import React from 'react'
import styled from 'styled-components'

import NumberText from 'components/2022/plebiscito/NumberText'
import { colors, spaces } from 'components/global-parameters'

export const ProgressBar = ({
  bordered = false,
  className,
  height = '39em',
  label,
  labeled = false,
  options,
  separatorBlend,
  textBlend,
  total
}) => {

  return (
    <Chart className={className}>
      <ChartBar bordered={bordered}
        height={height}
        separatorBlend={separatorBlend}
        textBlend={textBlend}
      >
        {options?.map((op, idx) => (
          <React.Fragment key={op.key + op.color}>
            <ChartBarOption
              color={op.color}
              style={{ flexGrow: total ? op.count / total : 0}}
            >
            </ChartBarOption>
            {labeled && (
              <Label align={idx && 'right'}
                separatorBlend={separatorBlend}
                textBlend={textBlend}
              >
                <NumberText value={op.count} />
                {label && ` ${label}`}
              </Label>
            )}
          </React.Fragment>
        ))}
      </ChartBar>
    </Chart>
  )
}

export const Chart = styled.div`
  position: relative;
  width: 100%;
`

export const ChartBar = styled.div`
  display: flex;
  height: ${props => props.height};
  border: ${props => props.bordered && 'solid 1em'};
  background-color: ${colors.defaultBarColor};
  &::after {
    ${({ separatorBlend }) => separatorBlend && 'mix-blend-mode: difference;'}
    background-color: ${colors.colorWhite};
    content: '';
    display: block;
    top: 0;
    left: calc(50% - 1px);
    bottom: 0;
    position: absolute;
    width: 2px;
  }
`

const Label = styled.span`
  ${props => props.align}: 0;
  color: ${colors.colorWhite};
  font-size: 12em;
  padding: 0 4px;
  position: absolute;
  ${({ textBlend }) => textBlend && 'mix-blend-mode: difference;'}
  @media(max-width: ${spaces.tabletBreakpoint}){
    display:none;
  }
`

export const ChartBarOption = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  background-color: ${({ color }) => color};
`


export default ProgressBar