import React from 'react'
import styled from 'styled-components'

import InfoTooltipBase from 'components/2022/plebiscito/Summary/Participation/InfoTooltip'
import ParticipationItem from 'components/2022/plebiscito/Summary/Participation/ParticipationItem'
import { colors, spaces } from 'components/global-parameters'


const Header = ({className}) => (
  <HeaderWrapper className={className}>
    <Label ><b>ELECCIÓN</b></Label>
    <Label >VOTAN</Label>
    <Label></Label>
    <Label>NO VOTAN</Label>
    <Label >INSCRITOS</Label>
  </HeaderWrapper>
)

export const ParticipationProgress = ({
  className,
  comparedOptions,
  salida,
}) => {
  const message = 'Es la suma de todos los votos válidamente emitidos, nulos y blancos'
  return salida && (
    <Wrapper className={className}>
      <Title>
        <Label>Participación</Label>
        <InfoTooltip
          message={message}
          placement={'top'}
        />
      </Title>
      <ChartTable>
        <Header />
        <ParticipationItem
          mobileLabel={salida.mobileLabel}
          padron={salida.participation.totalVotes}
          participation={salida.participation.cantidad}
          shortLabel={salida.shortLabel}
          votesTooltip={<InfoTooltip message={'$54.980 - $132.000 Valor multa'} />}
        />
        {comparedOptions.map(event => (
          event.selectedOption && (
            <ParticipationItem
              key={event.key}
              mobileLabel={event.mobileLabel}
              padron={event.participation.totalVotes}
              participation={event.participation.cantidad}
              shortLabel={event.shortLabel}
            />
          )
        ))}
      </ChartTable>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: grid;
  grid: 1fr / 1fr;
  align-items: center;
  width: 100%;
`

export const Label = styled.div`
  text-align: ${props => props.align || 'center'};
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`

const Title = styled.div`
  display: flex;
  justify-content: center;
  gap: 3px;
  > ${Label}{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
`

const InfoTooltip = styled(InfoTooltipBase)`
  align-self: center;
  justify-self: center;
  cursor: pointer;
  color: ${colors.black};
  width: 15px;
  height: 15px;
`

const ChartTable = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr 3fr 1fr 1fr;
  @media(max-width: ${spaces.tabletBreakpoint}){
    grid: 1fr / 1fr 1fr 3fr 1fr;
  }
`

export const HeaderWrapper = styled.div`
  display: contents;
  > * {
    border-bottom: 1px solid ${colors.colorBlack};
    padding-bottom: 3px;
    padding: 3px 10px;
  }
  > :first-child{
      justify-content: left;
    }
  @media(max-width: ${spaces.tabletBreakpoint}){
    > :last-child{
      display: none;
    }
  }

`


export default ParticipationProgress
