import React from 'react'
import styled from "styled-components"

import { colors } from 'components/global-parameters'
import { useRouter } from 'next/router'
import reportAnalytics from './reportAnalytics'

export const StyledButton = ({ disabledOption = false, key_, label, selectedOption, setSelectedOption }) => {
  const { pathname, query } = useRouter()
  const stringParams = JSON.stringify(query)

  const isSelected = (value) => selectedOption === value

  const sendGA = (key) => {
    reportAnalytics(key, `pathname: ${pathname} params: ${stringParams}`)
    isSelected(key_) && disabledOption ? setSelectedOption() : setSelectedOption(key_)
  }

  return (
    <Button className={isSelected(key_) && 'selected'}
      onClick={() => sendGA(key_)}
    >
      {label}
    </Button>
  )
}

const Button = styled.div`
    width: 100%;
    text-align: center;
    padding: 5px 7px;
    border: 1px solid ${colors.colorBlack};;
    cursor: pointer;
    background-color: ${colors.colorWhite};
    color: ${colors.colorBlack};
    &.selected{
        background-color: ${colors.colorBlack};;
        color: ${colors.colorWhite};
    }
    /* Disable select */
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
`

export default StyledButton
