import React from 'react'

import { normalNumberFormatter, oneDecimalFormatter } from 'components/global-parameters'


const NumberText = props => {
  const {
    className, isPercentage, value, ...other
  } = props
  const { format } = Number.isInteger(value) ? normalNumberFormatter : oneDecimalFormatter 
  return (
    <props.component className={className}
      {...other}
    >
      {format(value)}
      {isPercentage && '%'}
    </props.component>
  )
}

NumberText.defaultProps = {
  className: '',
  component: 'span',
  decimalSeparator: ',',
  isPercentage: false,
  roundTo: 2,
  thousandSeparator: '.',
  toFixed: null,
}

export default NumberText
