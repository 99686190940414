import styled from "styled-components"

import Image from "next/image"

export default function Controls({ onPause, onPlay, ...props }) {
  return (
    <Wrapper {...props}>
      <Button onClick={onPlay}>
        <Image
          alt='Play'
          height={12}
          src='/images/play.svg'
          width={12}
        />
      </Button>
      <Button onClick={onPause}>
        <Image
          alt='Pause'
          height={12}
          src='/images/pause.svg'
          width={12}
        />
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 3px;
`

const Button = styled.button`
  background-color: white;
  border: none;
  padding: 1px;
`