export const zones = {
  'granconcepcion': ['t.c.concepcion', 't.c.talcahuano', 't.c.coronel', 't.c.hualqui', 't.c.penco',
    't.c.lota', 't.c.tome', 't.c.sanpedrodelapaz', 't.c.ghiguayante', 't.c.hualpen'],
  'gransantiago': [
    't.c.cerrillos', 't.c.cerronavia', 't.c.conchali', 't.c.elbosque', 't.c.estacioncentral',
    't.c.huechuraba', 't.c.independencia', 't.c.lacisterna', 't.c.laflorida', 't.c.lagranja',
    't.c.lapintana', 't.c.lareina', 't.c.lascondes', 't.c.lobarnechea', 't.c.loespejo',
    't.c.loprado', 't.c.macul', 't.c.maipu', 't.c.nunoa', 't.c.pedroaguirrecerda',
    't.c.penanolen', 't.c.providencia', 't.c.pudahuel', 't.c.puentealto', 't.c.quilicura',
    't.c.quintanormal', 't.c.recoleta', 't.c.renca', 't.c.sanbernardo', 't.c.sanjoaquin',
    't.c.sanmiguel', 't.c.sanramon', 't.c.santiago', 't.c.vitacura',
  ],
  'granvalparaiso': ['t.c.valparaiso', 't.c.vinadelmar', 't.c.concon', 't.c.quilpue', 't.c.villaalemana']
}