import { Popover } from 'antd'
import React from 'react'
import styled from "styled-components"

import ResponsiveCircle from "components/2022/plebiscito/CountedTables/CircleGraph/Circles/ResponsiveCircle"
import reportAnalytics from 'components/2022/plebiscito/reportAnalytics'
import { spaces } from 'components/global-parameters'


export default function Circles({ circles = [], className, onMouseEnter, onMouseLeave, smallCircles }) {

  const sendEvent = (idx, value) => {
    onMouseEnter(idx)
    reportAnalytics('escrutada_event', value)
  }

  return (
    <Wrapper className={className}>
      {circles.map(({ color, label, popover, ...props }, idx) => {
        return (
          <React.Fragment key={label}>
            <PopoverWrapper>
              <Popover content={popover} >
                <Circle
                  color={color}
                  onMouseEnter={() => onMouseEnter(idx)}
                  onMouseLeave={onMouseLeave}
                  smallCircles={smallCircles}
                  {...props}
                >
                  {smallCircles ? '' : label}
                </Circle>
              </Popover>
            </PopoverWrapper>
            <CircleWrapper>
              <Circle
                color={color}
                onMouseEnter={() => sendEvent(idx, props.shortLabel)}
                onMouseLeave={onMouseLeave}
                {...props}
              >
                {smallCircles ? '' : label}
              </Circle>
            </CircleWrapper>
          </React.Fragment>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
  max-width: calc(${spaces.maxContent} - 370px);
  `

const PopoverWrapper = styled.div`
  display: none;
  @media(max-width: ${spaces.maxWidth}){
    display: inherit;
  }
`

const Circle = styled(ResponsiveCircle)`
  ${({ smallCircles }) => smallCircles && `width: 29px; height: 29px;`};
`

const CircleWrapper = styled.div`
  display: inherit;
  @media(max-width: ${spaces.maxWidth}){
    display: none;
  }
`