
import DataProvider from "components/2022/plebiscito/DataProvider"
import CurrentProvider from "components/CurrentProvider"
import RequestProvider from "components/RequestProvider"

export default function Providers2022(props) {
  return (
    <RequestProvider api={{
      id: '2022',
      url: process.env.NEXT_PUBLIC_API_URL_2022
    }}
    >
      <CurrentProvider api_id='2022'
        current_path='/Eventos/2022/Elecciones/8/_current.json'
      >
        <DataProvider {...props} />
      </CurrentProvider>
    </RequestProvider>
  )
}