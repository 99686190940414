import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import styled from "styled-components"

import territories from "components/2022/plebiscito/contextData/territories"
import zonesMapping from "components/2022/plebiscito/contextData/zonesMapping"
import CirclesGraphBase from "components/2022/plebiscito/CountedTables/CircleGraph"
import CardInfoBase from "components/2022/plebiscito/CountedTables/CircleGraph/CardInfo"
import { getRanges, getTimelineData } from "components/2022/plebiscito/CountedTables/helpers"
import Timeline from "components/2022/plebiscito/CountedTables/Timeline"
import TitleHeader, { Title as TitleBase } from "components/2022/plebiscito/TitleHeader"
import { useEnvironment } from "components/Environment"
import { percentFormatter, spaces } from "components/global-parameters"

const TRASVERSE_INTERVAL_TIME = 0.5

export default function CountedTables({ className }) {
  const { current, requests } = useEnvironment()
  const tables = Number(current.comunas.split('/').at(-1).split('_')[0]) / 10000  
  
  const queryParams = (url, enabled) => [
    [url],
    () => requests['2022']({ method: 'GET', url: url }),
    { enabled: Boolean(enabled || current) }
  ]

  const { data: mesasEscrutadas } = useQuery(...queryParams(current?.mesasescrutadas))

  const baseScrutinies = mesasEscrutadas
  const scrutinyDate = baseScrutinies && Date.parse(baseScrutinies[0]?.timestamp)
  const scrutinyTime = new Intl.DateTimeFormat('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/Toronto'
  }).format(scrutinyDate)

  const ranges = getRanges()
  const timelineData = getTimelineData({
    popoverFactory: ({ ratio, shortLabel }) => (
      <CardInfo label={shortLabel}
        percentage={ratio}
      />
    ),
    ranges,
    scrutinies: baseScrutinies,
    territories,
    zonesMapping
  })

  let lastTimelineIndex = -1
  timelineData.forEach(({ timeLabel }, idx) => {
    if (timeLabel < scrutinyTime) {
      lastTimelineIndex = idx
    }
  })

  const [selectedTimeIndex, setSelectedTimeIndex] = useState()
  const selectedTime = timelineData[selectedTimeIndex]
  const [trasverseTimelineInterval, setTrasverseTimelineInterval] = useState(false)

  const startTrasverse = () => {
    const nextIndex = selectedTimeIndex + 1
    const nextHasData = timelineData[nextIndex]?.countedTablesPercentage
    const trasverEnded = !nextHasData
    if (trasverEnded) {
      setSelectedTimeIndex(0)
    }
    setTrasverseTimelineInterval(true)
  }

  useEffect(() => {
    if (selectedTimeIndex === undefined && mesasEscrutadas) setSelectedTimeIndex(mesasEscrutadas.length - 1)
    const nextIndex = selectedTimeIndex + 1
    const nextHasData = timelineData[nextIndex]?.countedTablesPercentage
    const interval = trasverseTimelineInterval
      && nextHasData
      && setInterval(
        () => setSelectedTimeIndex(nextIndex),
        TRASVERSE_INTERVAL_TIME * 1000
      )
    return () => {
      if (trasverseTimelineInterval) {
        clearInterval(interval)
        const trasversingEnded = !timelineData[selectedTimeIndex]?.countedTablesPercentage
        if (trasversingEnded) {
          setTrasverseTimelineInterval(false)
        }
      }
    }
  }, [selectedTimeIndex, timelineData, trasverseTimelineInterval, mesasEscrutadas])

  if (!timelineData) return '...loading'

  const lastMode = true
  const { format } = percentFormatter
  return (
    <Wrapper className={className}>
      <Title>
        <Percentage>
          {format(tables)}
        </Percentage> de mesas escrutadas
      </Title>
      <Timeline
        current={selectedTimeIndex}
        labels={timelineData.map(({ timeLabel }) => timeLabel)}
        lastLabelIdx={lastMode ? lastTimelineIndex : baseScrutinies?.length - 1}
        lastMode={lastMode}
        onChange={setSelectedTimeIndex}
        onPause={() => setTrasverseTimelineInterval(false)}
        onPlay={() => startTrasverse()}
      />
      <CirclesGraph
        circles={selectedTime?.territories}
        ranges={getRanges()}
      />
    </Wrapper>
  )
}


const Wrapper = styled.div`
  font-size: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  @media(max-width: ${spaces.tabletBreakpoint}){
    font-size: 9px;
    max-width: 500px;
  }
`

const Title = styled(TitleHeader)`
  ${TitleBase} {
    font-size: 3em;
  }
`

const Percentage = styled.b`
  font-weight: 700;
`

const CirclesGraph = styled(CirclesGraphBase)`
  @media(max-width: ${spaces.tabletBreakpoint}){
    width: 338px;
  }
`

const CardInfo = styled(CardInfoBase)`
  display: none;
  @media(max-width: ${spaces.maxWidth}){
    display: contents;
  }
`