import React from 'react'
import styled from "styled-components"

import RegionInput, { InputStyled } from 'components/2022/plebiscito/FilterInput'
import MultiSelectionButton from 'components/2022/plebiscito/MultiSelectionButton'
import { spaces } from 'components/global-parameters'

export const SearchFilter = ({ className, comparedElection, electionOptions, inputFilter, onChangeComparedElection, onChangeInputFilter, onChangeSelectedUnit, selectedUnit }) => {

  const regionInput = (
    <RegionInput placeholder={'Filtrar por comuna'}
      regionFilter={inputFilter}
      regionHandleChange={onChangeInputFilter}
    />
  )

  const comparedElectionWrapper = (
    <ComparedElectionWrapper>
      <Label>{'COMPARAR'}</Label>
      <ComparedElectionButton disabledOption
        options={electionOptions.map(({ electionKey, label }) => ({ key: electionKey, label }))}
        selectedOption={comparedElection}
        setSelectedOption={onChangeComparedElection}
      />
    </ComparedElectionWrapper>
  )

  const regularSearchFilter = (
    <RegularSearchFilter>
      {regionInput}
      {comparedElectionWrapper}
      <MultiSelectionButton options={[{ key: 'porcentaje', label: 'Porcentaje' }, { key: 'cantidad', label: 'Cantidad' }]}
        selectedOption={selectedUnit}
        setSelectedOption={onChangeSelectedUnit}
      />
    </RegularSearchFilter>
  )

  const mobileSearchFilter = (
    <MobileSearchFilter>
      {comparedElectionWrapper}
      <MobileBottomWrapper>
        {regionInput}
        <DatatypeSelector options={[{ key: 'porcentaje', label: '%' }, { key: 'cantidad', label: 'Nº' }]}
          selectedOption={selectedUnit}
          setSelectedOption={onChangeSelectedUnit}
        />
      </MobileBottomWrapper>
    </MobileSearchFilter>
  )
  return (
    <Wrapper className={className}>
      {regularSearchFilter}
      {mobileSearchFilter}
    </Wrapper>
  )
}


const ComparedElectionButton = styled(MultiSelectionButton)`
    gap:10px;
    > * {
        width: max-content;
    }
`

const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 2px;
    @media(max-width: ${spaces.tabletBreakpoint}){
        font-size: 11px;
        line-height: 15px;
    }
`

const RegularSearchFilter = styled.div`
    display: contents;
`
const MobileSearchFilter = styled.div`
    display: contents;
`

const DatatypeSelector = styled(MultiSelectionButton)`
    width: 70px;
`

const MobileBottomWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const ComparedElectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Wrapper = styled.div`
    display: flex;
    align-items: end;
    justify-content: space-between;
    gap: 12px;
    ${RegularSearchFilter}{
        display: contents;
    }
    ${MobileSearchFilter}{
        display: none;
    }
    @media(max-width: ${spaces.tabletBreakpoint}){
        flex-direction: column;
        align-items: center;
        ${RegularSearchFilter}{
            display: none;
        }
        ${MobileSearchFilter}{
            display: contents;
        }
        & ${InputStyled}{
            width: 70px;
        }
    }
`

export default SearchFilter

