
import styled from "styled-components"

import { colors } from "components/global-parameters"

export default function Caption({ className, ranges = [] }) {
  return (
    <Wrapper className={className}
      rangesNumber={ranges.length + 1}
    >
      {ranges.map(({ color, label }) => {
      return (
        <Range key={label}>
          <Label>
            {label}
          </Label>
          <Rectangle color={color} />
        </Range>
      )
    })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: min-content;
  gap: 3px;
`

const Range = styled.div`
  width: 100%;
  display: grid;
  grid: min-content min-content / auto;
  justify-items: center;
`

const Label = styled.span`
  font-size: 11px;
`

const Rectangle = styled.div`
  width: 61px;
  height: 9px;
  background-color: ${({ color }) => color || colors.colorGrey1};
`