import ReactGA from "react-ga4"

export default function reportAnalytics(component, ...args) {
  const analytics = {
    'escrutada_event': {
      action: 'OnMouseEvent on  Circle Label',
      category: 'Display % Information From Region',
      label: `Popup de la información de la región ${args[0]}`,
    },
    'iframe_event': {
      action: 'Medium Used iframe',
      category: 'Iframe',
      label: `Medio ${args[0]} ha utilizado el iframe`,
    },
    'live_video_event': {
      action: 'Clicked on Live Component',
      category: 'Live',
      label: `Se clickea en el componente para ver el video en vivo modo ${args[0]}`,
    },
    'mobile_live_video_event': {
      action: 'Clicked on Live Component',
      category: 'Live Mobile',
      label: `Se clickea en el componente para ver el video en vivo desde un móvil`,
    },
    'pEntrada': {
      action: 'Clicked on “2020 Plebiscito” Button Resultado por Comunas',
      category: 'Display Information',
      label: `Desplegar barra porcentajes Apruebo vs Rechazo en /2022/plebiscito por región en ${args[0]}`,
    },
    'pEntradaSummary': {
      action: 'Clicked on “2020 Plebiscito” Button Summary',
      category: 'Display Information',
      label: `Desplegar barra porcentajes Apruebo vs Rechazo en /2022/plebiscito en ${args[0]}`,
    },
    'region_navegation': {
      action: `Clicked on Region ${args[0]}`,
      category: 'Navegation',
      label: `Se navega desde ${args[1]} hacia el endpoint ${args[2]}`,
    },
    'sVuelta': {
      action: 'Clicked on “2021 2da Vuelta” Button Resultado por Comunas',
      category: 'Display Information',
      label: `Desplegar barra porcentajes Kast vs Boric en /2022/plebiscito por región en ${args[0]}`,
    },
    'sVueltaSummary': {
      action: 'Clicked on “2021 2da Vuelta” Button Summary',
      category: 'Display Information',
      label: `Desplegar barra porcentajes Kast vs Boric en /2022/plebiscito en ${args[0]}`,
    },
    'timeline_event': {
      action: 'Click event on Time Line',
      category: 'Display % Information for specific hour',
      label: `Se eligió la hora ${args[0]} del timeline de mesas escrutadas mediante el ${args[1]}`,
    }
  }
  ReactGA.event(analytics[component])
}