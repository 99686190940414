import React, { useState } from 'react'
import styled from 'styled-components'

import LiveIndicator from 'components/2022/YoutubeLiveVideo/LiveIndicator'
import { colors, spaces } from 'components/global-parameters'
import Image from 'next/image'
import reportAnalytics from '../plebiscito/reportAnalytics'

export const YoutubeLiveVideo = ({
  autoplay = true,
  expandedHeight = 443,
  expandedWidth = 799,
  mobileAutoplay = false,
  smallHeight = 136,
  smallWidth = 246,
  videoId
}) => {

  const [isActive, setIsActive] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const send_signal_mobile = () => {
    setIsActive(!isActive)
    !isActive && reportAnalytics('mobile_live_video_event')
  }

  const send_signal_left = () => {
    setIsActive(true)
    setIsExpanded(!isExpanded)
    !isActive && reportAnalytics('live_video_event', 'expand')
  }

  const send_signal_right = () => {
    setIsActive(!isActive)
    setIsExpanded(false)
    !isActive && reportAnalytics('live_video_event', 'dropdown')
  }

  const viewHeight = isExpanded ? expandedHeight : smallHeight
  const viewWidth = isExpanded ? expandedWidth : smallWidth
  const sourceUrl = `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&autohide=1&autoplay=${autoplay ? '1' : '0'}`
  const mobileSourceUrl = `https://www.youtube.com/embed/${videoId}?fs=0&rel=0&modestbranding=1&controls=1&showinfo=0&autohide=1&autoplay=${mobileAutoplay ? '1' : '0'}`
  const allowParams = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'

  const caretUp = (
    <Image alt={'caret_down'}
      height='10.67px'
      src={'/images/caret_down.svg'}
      width='12.78px'
    />
  )
  const caretDown = (
    <Image alt={'caret_up'}
      height='10.67px'
      src={'/images/caret_up.svg'}
      width='12.78px'
    />
  )
  const shrinkArrow = (
    <Image alt={'shrink_arrow'}
      height='9.75px'
      src={'/images/shrink_arrow.svg'}
      width='9.75px'
    />
  )
  const growArrow = (
    <Image alt={'grow_arrow'}
      height='9.75px'
      src={'/images/grow_arrow.svg'}
      width='9.75px'
    />
  )

  const mobileContent = (
    <MobileContainer>
      <LiveIndicator
        animated={!isActive}
        centered
        handleClick={() => send_signal_mobile()}
      />
      {isActive && (
        <MobileVideoContainer
          active={isActive}
        >
          <MobileIframe allow={allowParams}
            frameBorder='0'
            src={mobileSourceUrl}
            title={'YouTube Video Player'}
          />
          <MobileVideoLabelContainer>
            <LiveIndicator />
            <a href={`https://www.youtube.com/watch?v=${videoId}}`}
              rel='noreferrer'
              target={'_blank'}
            >
              <MobileVideoLabel>
                <span>{'Análisis en Vivo'}</span>
              </MobileVideoLabel>
            </a>
          </MobileVideoLabelContainer>
          <CloseButton alt={'close'}
            height='9.75px'
            onClick={() =>
              setIsActive(false)}
            src={'/images/close.svg'}
            width='9.75px'
          />
        </MobileVideoContainer>
      )}
    </MobileContainer>
  )

  const webContent = (
    <Container
      height={
        isActive
          ? viewHeight + YoutubeLiveVideo.headerHeight
          : YoutubeLiveVideo.headerHeight}
      width={viewWidth}
    >
      <Header o>
        <ActionButton
          onClick={() => send_signal_left()}
          role='button'
        >
          {isExpanded ? shrinkArrow : growArrow}
        </ActionButton>
        <LiveIndicator centered={false} />
        <ActionButton
          onClick={() => send_signal_right()}
          role='button'
        >
          {!isActive ? caretUp : caretDown}
        </ActionButton>
      </Header>
      <VideoContainer
        height={isActive ? viewHeight : 0}
        width={viewWidth}
      >
        {isActive && (
          <Iframe allow={allowParams}
            allowFullScreen
            frameborder='0'
            src={sourceUrl}
            title={'YouTube Video Player'}
          />
        )}
      </VideoContainer>
    </Container>
  )

  return (
    <Wrapper>
      {mobileContent}
      {webContent}
    </Wrapper>
  )
}

const mobileHeight = '70px'

const Container = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  padding: 0;
  background-color: ${colors.colorWhite};
  transition: height, width, 0.35s;
  overflow: hidden;
  z-index: 2;
`
const Header = styled.div`
  display: grid;
  grid: 1fr / 14px 1fr 14px;
  width: 100%;
  padding: 10px 18px;
  column-gap: 24px;
  height: ${YoutubeLiveVideo.headerHeight - 2}px;
  justify-content: stretch;
  align-items: center;
  border: solid 1px ${colors.colorGrey15};
  transition: width 0.35s;
  background-color: ${colors.colorWhite};
`
const ActionButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 22px;
  height: 22px;
  :hover {
    cursor: pointer;
  }
`
const VideoContainer = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  transition: height, width 0.35s;
`
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`

const MobileVideoContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  height: ${mobileHeight};
  width: 100%;
  background-color: ${colors.colorWhite};
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  display: grid;
  flex-direction: column;
  grid: 1fr / 140px 4fr 1fr;
  justify-content: center;
  align-items: center;
`

const MobileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${colors.colorGrey13};
  padding: 4px 15px;
  width: 20%;
  min-width: 80px;
  background-color: ${colors.colorWhite};
  z-index: 2;
`
const MobileIframe = styled.iframe`
  height: ${mobileHeight};
  width: 100%;
`
const MobileVideoLabelContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-left: 20px;
`
const MobileVideoLabel = styled.div`
  font-size: 14px;
  color: ${colors.colorBlack};
  font-weight: 400;
  display: flex;
  flex-direction: column;
`
const CloseButton = styled(Image)`
  :hover {
    cursor: pointer;
  }
  justify-self: flex-start;
`


const Wrapper = styled.div`
  ${MobileContainer}{
      display: none;
  }
  @media(max-width: ${spaces.tabletBreakpoint}){
    z-index: 2;
      ${Container}{
          display: none;
      }
      ${MobileContainer}{
          display: grid;
      }
  }
`

export default YoutubeLiveVideo
