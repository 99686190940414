import React from 'react'
import styled from 'styled-components'

import { colors, spaces } from 'components/global-parameters'

export const ScrutinyProgress = ({ className, updatedAt }) => {
  const date = Date.parse(updatedAt)
  const time = new Intl.DateTimeFormat('es-ES', {
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
    timeZone: 'America/Toronto'
  }).format(date)

  const day = new Intl.DateTimeFormat('es-ES', {
    day: 'numeric',
    timeZone: 'America/Toronto'
  }).format(date)

  const month = new Intl.DateTimeFormat('es-ES', {
    month: 'short',
    timeZone: 'America/Toronto'
  }).format(date)

  const message = `ÚLTIMA ACTUALIZACIÓN A LAS ${time} EL ${day} ${month}`

  return (
    <Message className={className}>{message}</Message>
  )
}

const Message = styled.span`
  display: flex;
  font-size: ${spaces.s};
  justify-content: center;
  align-items: center;
  color: ${colors.colorGrey3};
  text-transform: uppercase;
`

export default ScrutinyProgress