import _ from "lodash"

export const getElectionResults = ({ electionData, label, mobileLabel, optionA, optionB, shortLabel, totalVotantes }) => {
  const stats = getVotesSummary({ electionData, optionA, optionB, totalVotantes })
  const { padron, total, totalOptionA, totalOptionB, totalValid } = stats
  const results = {
    label,
    mobileLabel,
    options: [
      {
        cantidad: totalOptionA,
        color: optionA.color,
        label: optionA.shortLabel || optionA.label,
        porcentaje: (totalOptionA / totalValid)
      },
      {
        cantidad: totalOptionB,
        color: optionB.color,
        label: optionB.shortLabel || optionB.label,
        porcentaje: (totalOptionB / totalValid)
      }
    ],
    participation: {
      cantidad: total,
      porcentaje: (total / padron),
      totalVotes: padron
    },
    shortLabel: shortLabel || label,
    totalVotes: totalValid
  }

  results.options.sort((a, b) => b.cantidad - a.cantidad)
  return results
}

export const getVotesSummary = ({ electionData, optionA, optionB, totalVotantes }) => {
  const totalOptionA = _.sumBy(electionData, (territory) => (territory[optionA.field]) || 0)
  const totalOptionB = _.sumBy(electionData, (territory) => (territory[optionB.field]) || 0)
  const totalBlank = _.sumBy(electionData, (territory) => (territory.BLANCO) || 0)
  const totalNull = _.sumBy(electionData, (territory) => (territory.NULO) || 0)
  const padron = _.sumBy(electionData, (territory) => (territory[totalVotantes]) || 0)
  const totalValid = totalOptionA + totalOptionB
  const total = totalValid + totalBlank + totalNull
  const participation = total / padron
  return { padron, participation, total, totalBlank, totalNull, totalOptionA, totalOptionB, totalValid }
}
