import _ from "lodash"

import { between } from "components/2022/plebiscito/helpers"
import { colors } from "components/global-parameters"

export const labels = ['18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00']
export const initialScrutiny = [{
  countedTablesPercentage: 0,
  territories: [
    {
      ratio: 0,
      territoryId: 't.r.dearicayparinacota'
    },
    {
      ratio: 0,
      territoryId: 't.r.detarapaca'
    },
    {
      ratio: 0,
      territoryId: 't.r.deantofagasta'
    },
    {
      ratio: 0,
      territoryId: 't.r.deatacama'
    },
    {
      ratio: 0,
      territoryId: 't.r.decoquimbo'
    },
    {
      ratio: 0,
      territoryId: 't.r.devalparaiso'
    },
    {
      ratio: 0,
      territoryId: 't.r.metropolitanadesantiago'
    },
    {
      ratio: 0,
      territoryId: 't.r.dellibertadorgeneralbernardoohiggins'
    },
    {
      ratio: 0,
      territoryId: 't.r.delmaule'
    },
    {
      ratio: 0,
      territoryId: 't.r.denuble'
    },
    {
      ratio: 0,
      territoryId: 't.r.delbiobio'
    },
    {
      ratio: 0,
      territoryId: 't.r.delaaraucania'
    },
    {
      ratio: 0,
      territoryId: 't.r.delosrios'
    },
    {
      ratio: 0,
      territoryId: 't.r.deloslagos'
    },
    {
      ratio: 0,
      territoryId: 't.r.deaysendelgeneralcarlosibanezdelcampo'
    },
    {
      ratio: 0,
      territoryId: 't.r.demagallanesydelaantarticachilena'
    },
  ],
  timestamp: "2022-08-02T15:00:00"
}]

export const formatTimestamp = ({ timestamp }) => {
  const date = new Date(timestamp)
  const timeFormatter = new Intl.DateTimeFormat(
    'es-ES',
    {
      hour: 'numeric',
      hour12: false,
      minute: 'numeric',
    })
  return timeFormatter.format(date)
}

export const getTimelineData = ({ popoverFactory, ranges, scrutinies, territories, zonesMapping }) => {
  if (!scrutinies) return []
  const withTerritoryData = scrutinies.map(({ mesas_escrutadas_chile, territories: byTerritory, total_mesas_chile }) => {
    const byRegion = _.groupBy(byTerritory, 'cod_region')
    const ratioMesasEscrutadasChile = mesas_escrutadas_chile / total_mesas_chile
    const withRatio = Object.entries(byRegion).map(([regionCode, regionScrutinies]) => {
      const totalMesas = _.sumBy(regionScrutinies, 'total_mesas')
      const totalMesasEscrutadas = _.sumBy(regionScrutinies, 'mesas_escrutadas')
      const ratioMesasEscrutadas = totalMesasEscrutadas / totalMesas
      const territory = territories.find(t => String(t.cod_region) === String(regionCode))
      return {
        order: zonesMapping.find(zone => zone.key === territory.n_key_region).order,
        ratio: isNaN(ratioMesasEscrutadas) ? 0 : ratioMesasEscrutadas,
        territoryId: territory.n_key_region
      }
    }).sort((territoryRatioA, territoryRatioB) => territoryRatioA.order - territoryRatioB.order)
    return {
      countedTablesPercentage: isNaN(ratioMesasEscrutadasChile) ? 0 : ratioMesasEscrutadasChile,
      territories: withRatio.map(({ ratio, territoryId }) => {
        const { acronym, label } = zonesMapping.find(
          t => t.key === territoryId
        )
        return {
          color: ranges.find(({ range }) => between(ratio, range)).color,
          label: acronym,
          onClick: () => console.log(territoryId),
          popover: popoverFactory && popoverFactory({ ratio, shortLabel: label }),
          ratio,
          shortLabel: label,
          territoryId
        }
      })
    }
  })
  const withLabels = labels.map((label, idx) => {
    const territory = withTerritoryData[idx]
    if (territory) {
      territory.timeLabel = label
      return territory
    } else {
      return { timeLabel: label }
    }
  }
  )
  return withLabels
}

export const getRanges = () => [
  {
    color: colors.colorGrey13,
    label: '<10%',
    range: [0, 0.1]
  },
  {
    color: colors.colorGrey15,
    label: "<50%",
    range: [0.1, 0.5]
  },
  {
    color: colors.colorGrey14,
    label: "<80%",
    range: [0.5, 0.8]
  },
  {
    color: colors.decidechileBlue,
    label: "80%+",
    range: [0.8, Infinity]
  },
]