import axios from "axios"

import Environment, { useEnvironment } from 'components/Environment'

const DEAFULT_API = {
  id: "default",
  url: "http://localhost:3000/api"
}

const getRequest = ({ client }) => {
  return async function (options) {
    const onSuccess = function (response) {
      return response.data
    }
    const onError = function (error) {
      return Promise.reject(error.response)
    }
    return client(options).then(onSuccess).catch(onError)
  }
}

export default function RequestProvider({
  api = DEAFULT_API,
  ...props
}) {
  const { requests = {} } = useEnvironment()

  const client = axios.create({
    baseURL: api.url
  })
  requests[api.id] = getRequest({ client })

  return (
    <Environment env={{ requests }}
      {...props}
    />
  )
}