
import { useState } from "react"
import styled from "styled-components"

import AtDecideChileBase from "components/2022/plebiscito/AtDecideChile"
import Caption from "components/2022/plebiscito/CountedTables/CircleGraph/Caption"
import CardInfoBase from "components/2022/plebiscito/CountedTables/CircleGraph/CardInfo"
import Circles from "components/2022/plebiscito/CountedTables/CircleGraph/Circles"
import { spaces } from "components/global-parameters"

export default function CircleGraph({ circles = [], className, ranges, smallCircles }) {
  const [sideInfoIndex, setSideInfoIndex] = useState(false)
  const selectedCircle = circles[sideInfoIndex]
  return (
    <Wrapper className={className}>
      <Circles
        circles={circles}
        onMouseEnter={setSideInfoIndex}
        onMouseLeave={() => setSideInfoIndex(false)}
        smallCircles={smallCircles}
      />
      <AtDecideChile />
      <Caption ranges={ranges} />
      {selectedCircle && (
        <SideCardInfo
          label={selectedCircle.shortLabel}
          percentage={selectedCircle.ratio}
        >
        </SideCardInfo>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: relative;
`

const AtDecideChile = styled(AtDecideChileBase)`
  font-size: 11px;
  align-self: flex-end;
`

const SideCardInfo = styled(CardInfoBase)`
  position: absolute;
  right: -180px;
  top: -20px;
  @media(max-width: ${spaces.maxWidth}){
    display: none;
  }
`