import React from 'react'
import styled from "styled-components"

import Hamburger from 'components/2022/Menu/Hamburger'
import NavigationLinks from 'components/2022/Menu/NavigationLinks'
import { colors, spaces } from 'components/global-parameters'

export default function Menu({ className }) {



  return (
    <Wrapper className={className}>
      <Navigation>
        <Hamburger />
        <NavigationLinks />
      </Navigation>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${spaces.maxContent};
  align-items: center;
  padding: 13px;
  background-color: ${colors.colorWhite};
  border-bottom: ${colors.headerGrey} solid 1px;
  border-top: ${colors.headerGrey} solid 1px;
`

const Navigation = styled.div`
  display: grid;
  grid: auto / min-content 1fr max-content;
  gap: 36px;
  align-items: center;
`


