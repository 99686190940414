import React from 'react'
import styled from 'styled-components'

import { colors } from 'components/global-parameters'

export const ElectionBar = ({ className, results }) => {
  return (
    <Chart className={className}>
      <ChartBar>
        {results.map(option => (
          <ChartBarCandidate
            color={option.color}
            key={option.label + option.color}
            style={{ flexGrow: option.vote_fraction }}
          />
        ))}
      </ChartBar>
    </Chart>
  )
}

export const Chart = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colors.defaultBarColor};
  &::after {
    background-color: ${colors.colorWhite};
    content: '';
    display: block;
    top: 0;
    left: calc(50% - 1px);
    bottom: 0;
    position: absolute;
    width: 2px;
  }
`

export const ChartBar = styled.div`
  display: flex;
`

export const ChartBarCandidate = styled.div`
  height: 39em;
  background-color: ${({ color }) => color || colors.defaultBarColor};
`

export const CandidateLoser = styled(ChartBarCandidate)`
  background-color: ${colors.defaultBarColor};
  &:nth-child(odd) {
    background-color: ${colors.defaultBarOddColor};
  }
`
export default ElectionBar