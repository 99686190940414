import { React, useEffect } from 'react'
import ReactGA from "react-ga4"
import styled from "styled-components"

import Footer from "components/2022/Footer"
import Header from "components/2022/Header"
import Menu from "components/2022/Menu"
import LoadingDecidechile from 'components/2022/plebiscito/LoadingDecidechile'
import Providers2022 from "components/2022/Providers2022"
import { useEnvironment } from "components/Environment"
import { spaces } from "components/global-parameters"
import { useRouter } from 'next/router'

function BaseLayout({ ...props }) {
  const { electionsData: { pEntrada, pSalida, sVuelta } } = useEnvironment()
  const noData = !pSalida || !pEntrada || !sVuelta

  return (
    <>
      <Header />
      <Menu />
      {noData ? <LoadingDecidechile /> : <Content {...props} />}
      <Footer />
    </>
  )
}

export default function Layout2022({ className, ...props }) {
  const { pathname, query } = useRouter()
  const stringParams = JSON.stringify(query)
  useEffect(() => {
    ReactGA.event({
      action: 'Change view through navegation',
      category: 'Navegation',
      label: `Usuario navega hacia ${pathname} con parámetros ${stringParams}`,
    })
  }, [pathname, stringParams])

  return (
    <Providers2022>
      <Wrapper className={className}>
        <BaseLayout {...props} />
      </Wrapper>
    </Providers2022>
  )
}

const Wrapper = styled.div`
  font-family: "JetBrains Mono";
  margin: 0 auto;
  max-width: ${spaces.maxWidth};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

export const Content = styled.div`
  padding: ${spaces.m};
  max-width: ${spaces.maxContent};
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
`
