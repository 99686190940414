import { useQuery } from '@tanstack/react-query'

import Environment, { useEnvironment } from 'components/Environment'

export default function CurrentProvider({ api_id = 'default', current_path = '/_current.json', ...props }) {
  const { requests } = useEnvironment()
  const { data: current } = useQuery(
    [api_id, 'current'],
    () => requests[api_id]({
      method: 'GET',
      url: current_path
    }),
    {
      refetchInterval: 1000 * 3,
      staleTime: 0,
    })

  if (!requests[api_id]) {
    return null
  }
  return (
    <Environment env={{ current }}
      {...props}
    />
  )
}