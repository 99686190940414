import React from "react"
import styled from "styled-components"

import { colors, spaces } from "components/global-parameters"
import reportAnalytics from "../../reportAnalytics"

export default function LineGraph({ className, current = 0, labels = [], lastLabelIdx = 0, lastMode, onChange }) {
  const mapLabels = [...labels]
  const firstLabel = mapLabels.shift()

  const sendEvent = (idx, label, type_of_component) => {
    onChange(idx)
    reportAnalytics('timeline_event', label, type_of_component)
  }
  return (
    <Wrapper lastMode={lastMode}>
      <Timeline className={className}
        labelsNumber={labels.length}
      >
        <Circle
          inactive={lastLabelIdx < 0}
          onClick={() => sendEvent(0, firstLabel, 'circle')}
          selected={current === 0}
        />
        {mapLabels.map((label, idx) => {
          const realIndex = idx + 1
          const inactive = realIndex > lastLabelIdx
          return (
            <React.Fragment key={label}>
              <Space>
                <Line inactive={inactive} />
              </Space>
              <Circle
                inactive={inactive}
                onClick={() => sendEvent(realIndex, label, 'circle')}
                selected={realIndex === current}
              />
            </React.Fragment>
          )
        })}
        <Label
          inactive={lastLabelIdx < 0}
          onClick={() => sendEvent(0, firstLabel, 'label')}
          selected={current === 0}
        >
          {firstLabel}
        </Label>
        {mapLabels.map((label, idx) => {
          const realIndex = idx + 1
          return (
            <React.Fragment key={label}>
              <Space />
              <Label
                inactive={realIndex > lastLabelIdx}
                onClick={() => sendEvent(realIndex, label, 'label')}
                selected={realIndex === current}
              >
                {labels[realIndex]}
              </Label>
            </React.Fragment>
          )
        })}
      </Timeline>
    </Wrapper>
  )
}

const Timeline = styled.div`
  display: grid;
  grid: 1fr / repeat(${({ labelsNumber }) => labelsNumber - 1}, min-content 1fr) min-content;
  width: 100%;
  align-items: center;
  justify-items: center;
  padding: 0 12px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  pointer-events: ${({ lastMode }) => lastMode ? 'none' : 'auto'};
`

const Space = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Line = styled.div`
  width: 100%;
  border-top: 2px solid ${({ inactive }) => inactive ? colors.colorGrey12 : colors.colorGrey1};
`

const Label = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  width: 1px;
  cursor: pointer;
  ${({ inactive }) => inactive && 'pointer-events: none'};
  color: ${({ inactive }) => inactive ? colors.colorGrey12 : colors.colorGrey1};
  @media(max-width: ${spaces.mobileBreakpoint}){
    font-size: 10px;
  }
`

const Circle = styled.div`
  border-radius: 50%;
  width: ${({ selected }) => selected ? '14px' : '8px'};
  height: ${({ selected }) => selected ? '14px' : '8px'};
  border: 2px solid ${({ inactive }) => inactive ? colors.colorGrey12 : colors.colorGrey1};
  background-color: white;
  cursor: pointer;
  ${({ inactive }) => inactive && 'pointer-events: none'};
  @media(max-width: ${spaces.mobileBreakpoint}){
    width: ${({ selected }) => selected ? '12px' : '6px'};
    height: ${({ selected }) => selected ? '12px' : '6px'};
  }
`
