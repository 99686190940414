import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import NumberText from "components/2022/plebiscito/Card/Number"
import ElectionBarBase, { ChartBarCandidate } from "components/2022/plebiscito/ElectionBar"
import { ELECTIONS_PROPERTIES } from "components/2022/plebiscito/helpers"
import { colors, oneDecimalFormatter } from "components/global-parameters"


function VotesTable({ electionKey, title, votesData, winner }) {
  const { optionA, optionB } = ELECTIONS_PROPERTIES[electionKey.toString()]
  return (
    <Table>
      <TableHeader title={title} />
      {
        votesData.map(({ key, percentage, title, votes }) => {
          const color = key?.replaceAll(' ', '_') === optionA.field ? optionA.color : optionB.color
          const resto = 1 - percentage / 100
          const resultsData = [
            {
              color,
              vote_fraction: percentage / 100,
            },
            {
              color: colors.colorGrey13,
              vote_fraction: resto,
            }
          ]
          const formattedVotes = votes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          const { format } = oneDecimalFormatter
          return (
            <React.Fragment key={title}>
              <Item>
                <Option color={color}
                  show={title === winner}
                  text={title}
                />
              </Item>
              <Item justify='end'>
                <NumberText
                  value={format(percentage)}
                />
              </Item>
              <Item justify='center'>
                <ElectionBar results={resultsData} />
              </Item>
              <Item justify='end'>
                <div>
                  {formattedVotes}
                </div>
              </Item>
            </React.Fragment>
          )
        })
      }
    </Table>
  )
}

function TableHeader({ title }) {
  return (
    <React.Fragment key={title}>
      <Election justify='start'>
        {title}
      </Election>
      <HeaderItem>
        %
      </HeaderItem>
      <HeaderItem />
      <HeaderItem>
        Votos
      </HeaderItem>
    </React.Fragment>
  )
}

function Option({ color, show, text }) {
  return (
    <OptionContainer>
      <Dot color={color}
        show={show}
      />
      <OptionText>
        {text.toLowerCase()}
      </OptionText>
    </OptionContainer>
  )
}

export const Dot = styled.div`
  background-color: ${({ color, show }) => show ? color : null};
  border-radius: 5px;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
`

const OptionText = styled.div`
  text-transform: capitalize;
`

const OptionContainer = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
`

const Table = styled.div`
  display: grid;
  grid:  auto / repeat(2, 3fr 1fr);
`
export const Item = styled.div`
  border-bottom: 0.5px solid ${colors.colorGrey15};
  display: flex;
  justify-content: ${({ justify }) => justify ? justify : 'start'};
  font-size: 13px;
  font-weight: 500;
  align-items: center;
`
export const HeaderItem = styled.div`
  border-bottom: 0.5px solid ${colors.black};
  font-size: 13px;
  display: flex;
  justify-content: ${({ justify }) => justify ? justify : 'end'};
`

const Election = styled(HeaderItem)`
  font-weight: 700;
`

export const ElectionBar = styled(ElectionBarBase)`
  ${ChartBarCandidate} {
   height: 2em;
  }
  padding: 1em;
  background-color: initial;
`

export const votesDataType = {
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  votes: PropTypes.number.isRequired,
}

VotesTable.propTypes = {
  title: PropTypes.string.isRequired,
  votesData: PropTypes.arrayOf(
    PropTypes.shape(votesDataType)
  ).isRequired,
}

TableHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export { ChartBarCandidate }

export default VotesTable