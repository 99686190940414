import { useQuery } from '@tanstack/react-query'

import Environment, { useEnvironment } from 'components/Environment'

export default function DestacadosProvider(props) {
  const { current, requests } = useEnvironment()

  const queryParams = (url, enabled) => [
    [url],
    () => requests['2022']({ method: 'GET', url: url }),
    { enabled: Boolean(enabled || current) }
  ]

  const { data: destacados } = useQuery(...queryParams(current?.destacados))

  return (
    <Environment env={{ highlights: destacados?.highlights }}
      {...props}
    />
  )
}