import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'antd'
import React from 'react'


export const InfoTooltip = ({ className, message, placement='bottom' }) => {
  return (
    <Popover
      className={className}
      content={message}
      overlayInnerStyle={{ fontFamily: 'JetBrains Mono' }}
      placement={placement}
    >
      <FontAwesomeIcon
        height={14}
        icon={faInfoCircle}
        width={14}
      />
    </Popover>
  )
}

export default InfoTooltip
