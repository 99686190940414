import { Select as SelectBase } from 'antd'
import styled from "styled-components"

import { colors, spaces } from 'components/global-parameters'
import Link from 'next/link'
import { useRouter } from 'next/router'

const { Option } = SelectBase

export const NAV_LINKS = [
  { href: '/2022/plebiscito', key: 'general', label: 'General', match: '/2022/plebiscito' },
  { href: '/2022/plebiscito/region/metropolitanadesantiago', key: 'regiones', label: 'Regiones', match: '/2022/plebiscito/region/[regionId]' },
  { href: '/2022/plebiscito/extranjero', key: 'extranjero', label: 'Extranjero', match: '/2022/plebiscito/extranjero' },
  { href: 'https://www.decidechile.cl/analisis', key: 'analisis_electoral', label: 'Análisis Electoral', newTab: true },
]

const SelectorMenu = ({ className, currentLink, links }) => {
  return (
    <Select
      bordered={false}
      className={className}
      defaultValue={currentLink}
      dropdownRender={(item) => <SelectItem>{item}</SelectItem>}
      value={currentLink}
    >
      {links.map(({ href, key, label }) => (
        <Option key={key}>
          <Link href={href}>
            <CustomLink>
              {label}
            </CustomLink>
          </Link>
        </Option>
      ))}
    </Select>
  )
}

const ButtonMenu = ({ className, currentLink, links }) => {
  const itemProps = ({ key, newTab }) => {
    const defaultProps = {
      active: currentLink === key,
    }
    if (newTab) {
      defaultProps.target = '_blank'
      defaultProps.rel = 'noopener noreferrer'
    }
    return defaultProps
  }
  return (
    <ButtonMenuWrapper className={className}>
      {links.map(({ href, key, label, newTab }) => (
        <Link href={href}
          key={key}
          passHref
        >
          <Item {...itemProps({ key, newTab })}>{label}</Item>
        </Link>
      ))}
    </ButtonMenuWrapper>
  )
}

export default function NavigationLinks() {
  const { pathname } = useRouter()

  const selectedValue = NAV_LINKS.find(
    ({ match }) => pathname === match
  )?.key

  return (
    <Wrapper>
      <ButtonMenu
        currentLink={selectedValue}
        links={NAV_LINKS}
      />
      <SelectorMenu
        currentLink={selectedValue}
        links={NAV_LINKS}
      />
    </Wrapper>
  )
}

const ButtonMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 36px;
`

const Select = styled(SelectBase)`
  width: 100%;
  max-width: 200px;
  .ant-select-arrow{
    color: ${colors.colorGrey8};
  }
`

const Wrapper = styled.div`
  ${Select} {
    display: none;
    .ant-select-selection-item {
      pointer-events: none;
    }
  }
  @media(max-width: ${spaces.tabletBreakpoint}){
    ${ButtonMenuWrapper} {
      display: none;
    }
    ${Select} {
      display: inherit;
    }
  }
`

const Item = styled.a`
  color: ${({ active }) => active ? colors.decidechileBlue : colors.colorGrey8};
  font-weight: 400;
  font-size: 14px;
  :hover{
      font-weight: 700;
      color: ${colors.decidechileBlue};
  }
`

const SelectItem = styled.span`
  font-family: JetBrains Mono;
`

const CustomLink = styled.div`
  > * {
    width: 100%;
    height: 100%;
  }
`

