export const spaces = {
  l: '36em',
  m: '24em',
  maxContent: '1024px',
  maxWidth: '1280px',
  mobileBreakpoint: '480px',
  s: '12em',
  tabletBreakpoint: '768px',
  xl: '48em',
  xs: '6em',
  xxl: '60em',
  xxs: '3em'
}

export const colors = {
  bdoBlue: "#1c4f9c",
  colorBlack: "#000000",
  colorBlack1: "#404040",
  colorBlack2: "#737373",
  colorBlue1: "#2150e8",
  colorBlueApruebo: "#00a9d1",
  colorGrey1: "#323232",
  colorGrey10: "#F5F5F5",
  colorGrey11: "#f2f2f2",
  colorGrey12: "#a8acae",
  colorGrey13: "#e0e0e0",
  colorGrey14: "#4f4f4f",
  colorGrey15: "#bdbdbd",
  colorGrey16: "#828282",
  colorGrey17: "#D9D9D9",
  colorGrey18: "#E0E0E0",
  colorGrey19: "#E8E8E8",
  colorGrey2: "#4c4c4c",
  colorGrey20: "#F0F0F0",
  colorGrey21: "#F8F8F8",
  colorGrey22: "#BFBFBF",
  colorGrey23: "#A6A6A6",
  colorGrey3: "#666666",
  colorGrey4: "#7f7f7f",
  colorGrey5: "#999999",
  colorGrey6: "#a0a0a0",
  colorGrey7: "#b4b4b4",
  colorGrey8: "#555555",
  colorGrey9: "#dddddd",
  colorGreyVersus: "#B6B6B6",
  colorRed1: "#FF0000",
  colorRedRechazo: "#ff3a3a",
  colorWhite: "#ffffff",
  colorYellow: "#fcbe31",
  decidechileBlue: "#2150E8",
  defaultBarColor: "#d0d0d0",
  defaultBarOddColor: "#e0e0e0",
  headerGrey: "#e0e1e0"
}

export const oneDecimalFormatter = new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
export const millionFormatter = new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, minimumFractionDigits: 2, notation: 'compact' })
export const percentFormatter = {
  format: (number) => {
    const formatter = new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'percent' })
    const formatted = formatter.format(number)
    return String(formatted).replace(' ', '')
  }
}
export const normalNumberFormatter = new Intl.NumberFormat('es-CL')
