import React from 'react'
import styled from 'styled-components'

import { colors } from 'components/global-parameters'

const AtDecideChile = (props) => (
  <Label href={'https://twitter.com/DecideChile'}
    target={'_blank'}
    {...props}
  >
    {'@DecideChile'}
  </Label>
)

const Label = styled.a`
  font-size: 12em;
  font-weight: 400;
  color: ${colors.decidechileBlue};
  text-decoration: underline;
  &:hover{
    color: ${colors.decidechileBlue};
  }
`

export default AtDecideChile
