
const country = 'es-CL'

function numberFormatterFactory(options) {
  return new Intl.NumberFormat(country, options)
}

function formattedNumber({ formatter, value}) {
  return formatter.format(value)
}

export function percentNumber(value) {
  const formatter = numberFormatterFactory({ maximumFractionDigits: 1, style: 'percent'})
  return formattedNumber({ formatter, value})
}

export function integerNumber(value) {
  const formatter = numberFormatterFactory({maximumFractionDigits: 0 })
  return formattedNumber({ formatter, value })
}



export default function Number({ className, value }) {
  return (
    <span className={className}>
      {value}
    </span>
  )
}