
import React from 'react'
import styled from "styled-components"

import { colors } from 'components/global-parameters'

export const FilterInput = ({ className, placeholder, regionFilter, regionHandleChange }) => {

  return (
    <Wrapper className={className}>
      <InputStyled
        onChange={({ target: { value } }) => regionHandleChange(value)}
        placeholder={placeholder}
        type='text'
        value={regionFilter}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export const InputStyled = styled.input`
    height: 27px;
    width: 100%;
    border: 1px solid ${colors.colorGrey15};
    background: ${colors.colorGrey11}80;
    padding-left: 10px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02px;
    color: ${colors.colorGrey14};

    font-family: inherit;

    :focus {
        outline: none !important;
    }
`

export default FilterInput