import React from 'react'
import styled from 'styled-components'

import NumberTextBase from 'components/2022/plebiscito/NumberText'
import { colors, oneDecimalFormatter } from 'components/global-parameters'


function ChoiceSummary ({
  align = 'left',
  color,
  label,
  verbose = true,
  voteCount = null,
  voteFraction = null
}) {
  const { format } = oneDecimalFormatter
  return (
    <Info align={align}
      key={`${label}info`}
    >
      <NamePercentage>
        <Name>
          <span>
            {label}
          </span>
        </Name>
        {voteFraction !== null && (
          <Percentage color={color}>
            <NumberText>{format(voteFraction * 100)}%</NumberText>
          </Percentage>
      )}
      </NamePercentage>
      {voteCount !== null && (
        <Votes>
          <NumberTextBase value={voteCount} />
          {verbose && ' votos'}
        </Votes>
    )}
    </Info>
  )
}

function ElectionVerbose({ className, optionLeft, optionRight, totalVotes }){
  return (
    <Wrapper className={className}>
      <ChoiceSummary
        color={optionLeft.color}
        key={optionLeft.label}
        label={optionLeft.label}
        voteCount={optionLeft.cantidad}
        voteFraction={totalVotes !== 0 ? optionLeft.porcentaje : 0}
      />
      <ChoiceSummary
        align={'right'}
        color={optionRight.color}
        key={optionRight.label}
        label={optionRight.label}
        voteCount={optionRight.cantidad}
        voteFraction={totalVotes !== 0 ? optionRight.porcentaje : 0}
      />
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr;
  width: 100%;
`

export const NamePercentage = styled.div`
  display: grid;
  line-height: initial;
`
export const NumberText = styled.span`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 2em;
`

export const Info = styled.div`
  text-align: ${(props) => props.align};
  width: 100%;
`

export const Name = styled.span`
  font-size: 24em;
  color: ${colors.colorBlack};
  font-weight: 300;
`

export const Percentage = styled.span`
  font-size: 16em;
  color: ${({ color }) => color};
`

export const Votes = styled.span`
  font-weight: 300;
  font-size: 16em;
`

export default ElectionVerbose
