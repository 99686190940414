import {css} from 'styled-components'

import { colors } from 'components/global-parameters'

export const skeletonColor = css`
  background-color: ${colors.colorGrey19};
  color: ${colors.colorGrey19};
  border: none;
  mix-blend-mode: normal;
  pointer-events: none;
  user-select: none;
`

export default skeletonColor
