import React from 'react'
import styled from 'styled-components'

import NumberText from 'components/2022/plebiscito/NumberText'
import ProgressBarBase from 'components/2022/plebiscito/Summary/ProgressBar'
import { colors, millionFormatter, spaces } from 'components/global-parameters'


export const ParticipationItem = ({
  className,
  mobileLabel,
  padron,
  participation = 0,
  shortLabel,
  votesTooltip,
}) => {
  const options = [{ color: colors.colorBlack, count: participation, key: shortLabel },
  { color: colors.colorWhite, count: (padron - participation), key: `no_${shortLabel}` }
  ]
  const { format } = millionFormatter
  return (
    <Wrapper className={className}>
      <Label>
        <span>{shortLabel}</span>
        <span>{mobileLabel}</span>
      </Label>
      <VotesWrapper align={'right'}>
        <Votes
          isPercentage
          value={(participation / padron) * 100}
        />
      </VotesWrapper>
      <ElectionChart>
        <ProgressBar bordered
          height={'19px'}
          labeled
          options={options}
          separatorBlend
          textBlend
          total={padron}
        />
      </ElectionChart>
      <VotesWrapper align={'left'}>
        <Votes 
          isPercentage
          value={((padron - participation) / padron) * 100}
        />%
        &nbsp;
        {votesTooltip}
      </VotesWrapper>
      <Inscritos>{format(padron)}</Inscritos>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: contents;
  > * {
    border-bottom: 0.5px solid ${colors.colorGrey15};
    padding: 10px 0;
  }
  :hover{
    > * {
      background: ${colors.colorGrey10};
    }
  }
  @media(max-width: ${spaces.tabletBreakpoint}){
    > :last-child{
      display: none;
    }
  }
`

const Votes = styled(NumberText)`
  font-size: 14em;
  font-weight: 700;
`

const ProgressBar = styled(ProgressBarBase)`
  & :after{
    margin: 1px 0;
  }
`

const Label = styled.div`
  font-size: 14em;
  text-align: left;
  padding-left: 10px;

  >:nth-child(2){
    display:none;
  }
  >:nth-child(1){
    display:inline;
  }
  @media(max-width: ${spaces.tabletBreakpoint}){
    >:nth-child(2){
      display:inline;
    }
    >:nth-child(1){
      display:none;
    }
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1em;
`

const Inscritos = styled(Item)`
  font-size: 14em;
`

const VotesWrapper = styled(Item)`
  gap: 2px;
  text-align: ${props => props.align || 'center'};
`

const ElectionChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ParticipationItem

