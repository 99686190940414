import { createContext, useContext } from 'react'

const EnvironmentContext = createContext({})
export const useEnvironment = () => useContext(EnvironmentContext)

export const Environment = ({ env = {}, extend = true, ...props }) => {
  const environment = useEnvironment()
  const newEnv = extend ? {
    ...environment,
    ...env,
  } : env
  return (
    <EnvironmentContext.Provider value={newEnv}
      {...props}
    />
  )
}

export default Environment