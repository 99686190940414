import Lottie from 'react-lottie'
import styled from "styled-components"

import animationData from 'public/images/loading.json'

export default function LoadingDecidechile() {
  const defaultOptions = {
    animationData: animationData,
    autoplay: true,
    loop: true,
  }

  return (
    <Wrapper>
      <Logo>
        <Lottie
          options={defaultOptions}
        />
      </Logo>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 12px;
  justify-content: center;
  align-items: center;
`

const Logo = styled.div`
  display: flex;
  max-width: 100px;
`