import styled from "styled-components"

import Controls from "components/2022/plebiscito/CountedTables/Timeline/Controls"
import LineGraph from "components/2022/plebiscito/CountedTables/Timeline/LineGraph"

export default function Timeline({ className, current = 0, labels = [], lastLabelIdx, lastMode, onChange, onPause, onPlay }) {
  return (
    <Wrapper className={className}>
      {!lastMode && (
        <Controls onPause={onPause}
          onPlay={onPlay}
        />
      )}
      <LineGraph
        current={lastMode ? lastLabelIdx : current}
        labels={labels}
        lastLabelIdx={lastLabelIdx}
        lastMode={lastMode}
        onChange={onChange}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`