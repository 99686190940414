import React, { useState } from 'react'
import styled from "styled-components"

import { getElectionResults } from "components/2022/plebiscito/[regionId]/Communes/RegionSection/Summary/helpers"
import AtDecideChile from "components/2022/plebiscito/AtDecideChile"
import { ELECTIONS_PROPERTIES } from 'components/2022/plebiscito/helpers'
import { ComparativeButton, Results } from 'components/2022/plebiscito/Summary'
import ElectionBarVerbose from "components/2022/plebiscito/Summary/ElectionBarVerbose"
import { getSummaryData } from 'components/2022/plebiscito/Summary/helpers'
import ParticipationProgress from "components/2022/plebiscito/Summary/Participation"
import ScrutinyProgress from "components/2022/plebiscito/Summary/ScrutinyProgress"
import TitleHeaderBase from "components/2022/plebiscito/TitleHeader"
import { useEnvironment } from 'components/Environment'
import { colors, spaces } from 'components/global-parameters'

export default function Summary({ communeKey, communeName }) {

  const { current, electionsData: { pEntrada, pSalida, sVuelta } } = useEnvironment()

  const [selectedOptionPEntrada, setSelectedOptionPEntrada] = useState('pEntradaSummary')
  const [selectedOptionSVuelta, setSelectedOptionSVuelta] = useState()

  if (!pSalida || !pEntrada || !sVuelta) return 'loading...' //TODO Loading component

  const pSalidaSummary = {
    ...getElectionResults({
      electionData: [
        ...pSalida.nacional.filter(t => t.N_KEY === communeKey),
      ],
      ...ELECTIONS_PROPERTIES.pSalida
    })
  }

  const pEntradaSummary = {
    ...getElectionResults({
      electionData: [
        ...pEntrada.nacional.filter(t => t.N_KEY === communeKey),
      ],
      ...ELECTIONS_PROPERTIES.pEntrada
    }),
    selectedOption: selectedOptionPEntrada,
    setSelectedOption: setSelectedOptionPEntrada
  }

  const sVueltaSummary = {
    ...getElectionResults({
      electionData: [
        ...sVuelta.nacional.filter(t => t.N_KEY === communeKey),
      ],
      ...ELECTIONS_PROPERTIES.sVuelta
    }),
    selectedOption: selectedOptionSVuelta,
    setSelectedOption: setSelectedOptionSVuelta
  }

  const options = {
    pEntradaSummary,
    sVueltaSummary
  }

  const summaryData = getSummaryData(options)
  return (
    <Wrapper>
      <Top>
        <ScrutinyProgress updatedAt={current.updated_at} />
        <TitleHeader>{communeName}</TitleHeader>
        <ComparativeButton options={summaryData} />
        <AtDecideChile />
      </Top>
      <Results>
        <ElectionBarVerbose
          comparedOptions={summaryData}
          salida={pSalidaSummary}
        />
        <ParticipationProgress
          comparedOptions={summaryData}
          salida={pSalidaSummary}
        />
      </Results>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${spaces.m};
  border: 2px solid ${colors.colorRed1};
  @media(max-width: ${spaces.tabletBreakpoint}){
    position: sticky;
    left: 0;
    width: calc(100vw - ${spaces.m} - ${spaces.m});
  }
`

const Top = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: ${spaces.xs};
`

const TitleHeader = styled(TitleHeaderBase)`
  text-transform: capitalize;
`

