
import styled from "styled-components"

import { colors } from "components/global-parameters"

export default function ResponsiveCircle({ children, className, color, ...props }) {
  return (
    <Wrapper className={className}
      {...props}
    >
      <Circle color={color}>
        <Label>{children}</Label>
      </Circle>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 37px;
`

export const Circle = styled.div`
  background-color: ${({ color }) => color || colors.colorGrey1};
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: auto;
  padding-top: 100%;
`

const Label = styled.div`
  color: white;
  position: absolute;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`