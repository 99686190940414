import React from 'react'
import styled from "styled-components"

import { colors, spaces } from 'components/global-parameters'
import Image from 'next/image'
import Link from 'next/link'
import unholsterLogo from 'public/images/unholster-2021.png'

export default function Footer() {

  // Sponsor structure:
  // {
  //   name: 'BDO',
  //   headerText: 'Sponsored by',
  //   href: 'https://view.genial.ly/61953542c890770d91817b8b',
  //   logo: bdoLogo,
  //   footerText: 'Datos que crean valor',
  // },

  const sponsors = [
  ]

  return (
    <Wrapper>
      <Companies>
        <Company>
          <HeaderText>POWERED BY</HeaderText>
          <Link href='https://www.unholster.com'
            passHref
          >
            <a rel='noopener noreferrer'
              target='_blank'
            >
              <Image alt='Unholster'
                height={48}
                src={unholsterLogo}
                width={178}
              />
            </a>
          </Link>
        </Company>
        {sponsors.map(sponsor => (
          <Company key={sponsor.name}>
            <HeaderText>{sponsor.headerText}</HeaderText>
            <Link href={sponsor.href}
              passHref
              
            >
              <a rel='noopener noreferrer'
                target='_blank'
              >{sponsor.logo}</a>
            </Link>
          </Company>
        ))}
      </Companies>
      <Quote>
        Hecho con cariño y orgullo en Ñuñoa.
      </Quote>
      <Quote>
        <Link href='https://www.unholster.com/trabaja-con-nosotros'
          passHref
        >
          <a rel='noopener noreferrer'
            target='_blank'
          >Únete a nuestro equipo</a>
        </Link>
        
      </Quote>
    </Wrapper>
  )
}



const Wrapper = styled.div`
  max-width: ${spaces.maxContent};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px 24px;
`

const Companies = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
`

const Company = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`
const HeaderText = styled.div`
  color: ${colors.colorBlack};
  font-weight: 400;
  text-align: center;
  font-size: 11px;
`

const Quote = styled.div`
  font-size: 14px;
  text-align: center;
`