import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

import ComparedElectionVerboseBase from 'components/2022/plebiscito/Summary/ElectionBarVerbose/ComparedElectionBarVerbose'
import ElectionVerbose from 'components/2022/plebiscito/Summary/ElectionBarVerbose/ElectionVerbose'
import ProgressBar from 'components/2022/plebiscito/Summary/ProgressBar'
import { colors, spaces } from 'components/global-parameters'

export const ElectionBarVerbose = ({ className, comparedOptions, salida }) => {

  return (
    <Wrapper className={className}>
      <ElectionVerbose
        optionLeft={salida.options[0]}
        optionRight={salida.options[1]}
        totalVotes={salida.totalVotes}
      />
      <LabeledBar>
        <Label>
          <span>{salida.shortLabel}</span>
          <span>{salida.mobileLabel}</span>
        </Label>
        <StyledSortDown height={14}
          icon={faSortDown}
          width={14}
        />
        <ProgressBar
          height='39px'
          options={salida.options.map(op => ({ color: op.color, count: op.cantidad, key: op.label }))}
          total={salida.totalVotes}
        />
      </LabeledBar>
      {comparedOptions.map(event => (
        event.selectedOption && (
          <ComparedElectionVerbose
            key={event.key}
            mobileLabel={event.mobileLabel}
            options={event.options}
            shortLabel={event.shortLabel}
            totalVotes={event.totalVotes}
          />
      )
      ))}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: grid;
  grid: 1fr / 1fr;
`

const ComparedElectionVerbose = styled(ComparedElectionVerboseBase)`
  display: contents;
`

const LabeledBar = styled.div`
  font-size: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -18px;
`

export const Label = styled.span`
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.colorBlack};
  text-transform: capitalize;

  >:nth-child(2){
    display:none;
  }
  >:nth-child(1){
    display:inline;
  }
  @media(max-width: ${spaces.tabletBreakpoint}){
    >:nth-child(2){
      display:inline;
    }
    >:nth-child(1){
      display:none;
    }
  }
`

const StyledSortDown = styled(FontAwesomeIcon)`
  margin-bottom: 3px;
  color: ${colors.colorGrey13};
`

export default ElectionBarVerbose
