import { useQuery } from '@tanstack/react-query'

import { territoriesCsv } from 'components/2022/plebiscito/contextData/territories'
import { parseCsv } from 'components/2022/plebiscito/helpers'
import Environment, { useEnvironment } from 'components/Environment'

const API_URLS = {
  pEntradaCurrent: 'Eventos/2020/Elecciones/8/_current.json',
  sVueltaCurrent: 'Eventos/2021.12/Elecciones/4/_current.json',
}

export default function DataProvider(props) {
  const { current, requests } = useEnvironment()

  const queryParams = (url, enabled) => [
    [url],
    () => requests['2022']({ method: 'GET', url: url }),
    { enabled: Boolean(enabled || current) }
  ]

  const { data: pEntradaCurrent } = useQuery(...queryParams(API_URLS.pEntradaCurrent))
  const { data: sVueltaCurrent } = useQuery(...queryParams(API_URLS.sVueltaCurrent))

  const { data: pSalidaNacionalCsv } = useQuery(...queryParams(current?.comunas))
  const { data: pSalidaInternacionalCsv } = useQuery(...queryParams(current?.internacional))
  const { data: sVueltaNacionalCsv } = useQuery(...queryParams(sVueltaCurrent?.comunas, sVueltaCurrent))
  const { data: sVueltaInternacionalCsv } = useQuery(...queryParams(sVueltaCurrent?.internacional, sVueltaCurrent))
  const { data: pEntradaNacionalCsv } = useQuery(...queryParams(pEntradaCurrent?.comunas, pEntradaCurrent))
  const { data: pEntradaInternacionalCsv } = useQuery(...queryParams(pEntradaCurrent?.internacional, pEntradaCurrent))

  const parseElection = ({ internacionalCsv, nacionalCsv }) => {
    if (internacionalCsv && nacionalCsv) {
      const dataInternacional = parseCsv(internacionalCsv)
      const dataNacional = parseCsv(nacionalCsv)
      return {
        internacional: dataInternacional,
        nacional: dataNacional
      }
    }
  }

  const { data: territories } = parseCsv(territoriesCsv)

  const electionsData = {
    pEntrada: parseElection({
      internacionalCsv: pEntradaInternacionalCsv,
      nacionalCsv: pEntradaNacionalCsv
    }),
    pSalida: parseElection({
      internacionalCsv: pSalidaInternacionalCsv,
      nacionalCsv: pSalidaNacionalCsv
    }),
    sVuelta: parseElection({
      internacionalCsv: sVueltaInternacionalCsv,
      nacionalCsv: sVueltaNacionalCsv
    }),
  }

  return (
    <Environment env={{ electionsData, territories }}
      {...props}
    />
  )
}