import React from 'react'
import styled from "styled-components"

import { ELECTIONS_PROPERTIES } from "components/2022/plebiscito/helpers"
import RegionHeader from 'components/2022/plebiscito/RegionSection/RegionTable/RegionHeader'
import RegionItems from 'components/2022/plebiscito/RegionSection/RegionTable/RegionItems'
import { spaces } from 'components/global-parameters'

export const RegionTable = ({ className, data, firstColumnLabel = 'REGIÓN', onSort, selectedElection, selectedUnit, territoryImage }) => {
  const rowNumber = data.length
  if (!rowNumber) return null
  const selectedElections = Object.keys(data[0]?.results).map(electionKey => ELECTIONS_PROPERTIES[electionKey])
  const numberOfElections = selectedElections.length
  return (
    <Wrapper className={className}>
      <Table
        numberOfElections={numberOfElections}
        selectedElection={selectedElection}
      >
        <RegionHeader
          currentSort={() => console.log('currentSort')}
          firstColumnLabel={firstColumnLabel}
          headers={selectedElections}
          onSort={onSort}
          selectedUnit={selectedUnit}
          territoryImage={territoryImage}
        />
        {data.map(({
          href,
          icon,
          key,
          label,
          onClick,
          results,
          rowItem,
          selected,
          shortLabel
        }) => (
          <RegionItems
            href={href}
            icon={icon}
            key={key}
            label={shortLabel || label}
            onClick={onClick}
            results={results}
            rowItem={rowItem}
            selected={selected}
            territoryImage={territoryImage}
          />
        )
        )}
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    text-align: center;
    overflow-x: auto;
    width: 100%;
`

const Table = styled.div`
    display: grid;
    width: 100%;
    align-items: center;
    min-width: ${spaces.mobileBreakpoint};
    grid: 1fr / 1fr repeat(${({ numberOfElections }) => numberOfElections}, 1fr 2fr 1fr);
`


export default RegionTable

