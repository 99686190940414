import React, { useState } from 'react'
import styled from "styled-components"

import AtDecideChile from "components/2022/plebiscito/AtDecideChile"
import ComparativeButtonBase from "components/2022/plebiscito/Summary/ComparativeButton"
import ElectionBarVerbose from "components/2022/plebiscito/Summary/ElectionBarVerbose"
import { getSummaryData, resultsWithElectionProperties } from "components/2022/plebiscito/Summary/helpers"
import ParticipationProgress from "components/2022/plebiscito/Summary/Participation"
import ScrutinyProgress from "components/2022/plebiscito/Summary/ScrutinyProgress"
import TitleHeader from "components/2022/plebiscito/TitleHeader"
import { useEnvironment } from 'components/Environment'
import { spaces } from 'components/global-parameters'
import { Preliminar } from '../Card'

export default function Summary({ comparing = ['pEntrada'] }) {

  const { current, electionsData: { pEntrada, pSalida, sVuelta } } = useEnvironment()

  const [selectedOptionPEntrada, setSelectedOptionPEntrada] = useState(comparing.includes('pEntrada') && 'pEntradaSummary')
  const [selectedOptionSVuelta, setSelectedOptionSVuelta] = useState()

  const { pEntrada: pEntradaSummary, pSalida: pSalidaSummary, sVuelta: sVueltaSummary } = resultsWithElectionProperties({
    pEntrada,
    pSalida,
    sVuelta,
    selectedOptionPEntrada,
    selectedOptionSVuelta,
    setSelectedOptionPEntrada,
    setSelectedOptionSVuelta
  })

  const options = {
    pEntradaSummary,
    sVueltaSummary
  }

  const summaryOptions = getSummaryData(options)

  return (
    <Wrapper>
      <Top>
        {pSalidaSummary.isPreliminar && <Preliminar />}
        {pSalidaSummary.totalVotes && <ScrutinyProgress updatedAt={current.updated_at} />}
        <TitleHeader bold>Plebiscito de Salida</TitleHeader>
        <ComparativeButton options={summaryOptions} />
        <AtDecideChile />
      </Top>
      <Results>
        <ElectionBarVerbose
          comparedOptions={summaryOptions}
          salida={pSalidaSummary}
        />
        <ParticipationProgress
          comparedOptions={summaryOptions}
          salida={pSalidaSummary}
        />
      </Results>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Results = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spaces.xl};
`

const Top = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: ${spaces.xs};
  padding-top: ${spaces.l};
`

export const ComparativeButton = styled(ComparativeButtonBase)`
  margin-top: ${spaces.xs};
`
