import { Popover } from 'antd'
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import AtDecideChileBase from 'components/2022/plebiscito/AtDecideChile'
import VotesTable, { Dot, ElectionBar, HeaderItem, Item } from "components/2022/plebiscito/Card/Votes"
import skeletonColor from 'components/2022/plebiscito/iframe/Skeleton'
import NumberTextBase from 'components/2022/plebiscito/NumberText'
import ScrutinyProgressBase from 'components/2022/plebiscito/Summary/ScrutinyProgress'
import { colors, spaces } from "components/global-parameters"

const AVISO_PRELIMINAR = 'Esfuerzo de Unholster a partir de la informacion que esta saliendo en los medios.\n Servel entregará resultados oficiales de mesas en el extranjero a partir de las 18:00 hrs.'


function InternationalSummaryCard({ isSkeleton, participation, preliminar, sections, timestamp, title, winner }) {
  const color = winner ? (winner === 'Rechazo' ? colors.colorRedRechazo : colors.colorBlueApruebo) : colors.colorGrey13
  const { countedVotes, percentage, totalVotes } = participation
  return (
    <CardContainer isSkeleton={isSkeleton}>
      <ColorBar color={color} />
      <CardContent>
        <Top>
          <Title>
            <CountryName>{title.toLowerCase()}</CountryName>
            <Participation>
              <b>
                Participación&nbsp;
                <NumberText
                  isPercentage
                  value={Math.min(percentage, 100)}
                />
                &nbsp;
              </b>
              <span>
                <NumberText value={Math.min(countedVotes, totalVotes)} />
                /
                <NumberText value={totalVotes} />
              </span>
            </Participation>
          </Title>
          <Right>
            {preliminar && <Preliminar />}
          </Right>
        </Top>

        {
          sections.map(({ key, options, title, winner }) => {
            return (
              <VotesTable
                electionKey={key}
                key={title}
                title={title}
                votesData={options}
                winner={winner}
              />
            )
          })
        }
        <Footer>
          <ScrutinyProgress updatedAt={timestamp} />
          <AtDecideChile />
        </Footer>
      </CardContent>
    </CardContainer>
  )
};

export const Preliminar = ({ content = AVISO_PRELIMINAR }) => (
  <Popover
    content={content}
    overlayInnerStyle={{ fontFamily: 'JetBrains Mono' }}
  >
    <Ticket>PRELIMINAR</Ticket>
  </Popover>
)

function TicketElement({ className, show = true }) {
  return show ? (
    <div className={className}>
      PRELIMINAR
    </div>
  )
    :
    <div></div>
}



const CardContent = styled.div`
    padding: 14px;
    display: flex;
    flex-flow: column;
    gap: 20px;
`

const ColorBar = styled.div`
    height: 8px;
    background: ${({ color }) => color};
    width: 100%;
`

const Title = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
`

const Top = styled.div`
    display: grid;
    grid: 1fr / auto min-content;
    gap: 6px;
    width: 100%;
`

const Right = styled.div``


const CountryName = styled.h1`
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
`
const Ticket = styled.div`
    background-color: ${colors.colorYellow};
    font-size: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 8px;
    padding-right: 6px;
    border-radius: 2px;
    cursor: pointer;
`

const Footer = styled.div`
    display: flex;
    flex-flow: row;
    gap: 12px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`

const Participation = styled.div`
    display: flex;
    color: ${colors.colorGrey14};
    font-size: 12px;
    align-items: center;
    max-width: 70%;
    flex-wrap: wrap;
    @media(max-width: ${spaces.tabletBreakpoint}){
      flex-direction: column;
      align-items: flex-start;
    }
`

const ScrutinyProgress = styled(ScrutinyProgressBase)`
    font-size: 10px;
`
const AtDecideChile = styled(AtDecideChileBase)`
    font-size: 10px;
`
const NumberText = styled(NumberTextBase)`
`

const skeleton = css`
    ${Title} > :is(h1, div),
    ${Item} > :is(span, :not(${ElectionBar})),
    ${Ticket},
    ${HeaderItem},
    ${ColorBar},
    ${Dot},
    ${ScrutinyProgress}
    {
      ${skeletonColor}
    }
`

const CardContainer = styled.div`
    display: flex;
    flex-flow: column;
    min-width: 300px;
    width: 100%;
    background-color: white;
    border: 0.3px solid ${colors.colorGrey13};
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    ${({ isSkeleton }) => isSkeleton && skeleton}
`

TicketElement.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
}


export default InternationalSummaryCard
