
export const zonesMapping = [
  {
    acronym: 'AP',
    href: '/region/dearicayparinacota',
    icon: '/images/territories/arica_y_parinacota.svg',
    key: 't.r.dearicayparinacota',
    label: 'Arica y Parinacota',
    order: 1,
    shortLabel: 'Arica y Parinacota',
  },
  {
    acronym: 'TA',
    href: '/region/detarapaca',
    icon: '/images/territories/tarapaca.svg',
    key: 't.r.detarapaca',
    label: 'Tarapacá',
    order: 2,
    shortLabel: 'Tarapacá',
  },
  {
    acronym: 'AN',
    href: '/region/deantofagasta',
    icon: '/images/territories/antofagasta.svg',
    key: 't.r.deantofagasta',
    label: 'Antofagasta',
    order: 3,
    shortLabel: 'Antofagasta',
  },
  {
    acronym: 'AT',
    href: '/region/deatacama',
    icon: '/images/territories/atacama.svg',
    key: 't.r.deatacama',
    label: 'Atacama',
    order: 4,
    shortLabel: 'Atacama',
  },
  {
    acronym: 'CO',
    href: '/region/decoquimbo',
    icon: '/images/territories/coquimbo.svg',
    key: 't.r.decoquimbo',
    label: 'Coquimbo',
    order: 5,
    shortLabel: 'Coquimbo',
  },
  {
    acronym: 'VA',
    href: '/region/devalparaiso',
    icon: '/images/territories/valparaiso.svg',
    key: 't.r.devalparaiso',
    label: 'Valparaíso',
    order: 6,
    shortLabel: 'Valparaíso',
  },
  {
    acronym: 'RM',
    href: '/region/metropolitanadesantiago',
    icon: '/images/territories/metropolitana.svg',
    key: 't.r.metropolitanadesantiago',
    label: 'Metropolitana',
    order: 7,
    shortLabel: 'Metropolitana',
  },
  {
    acronym: 'OH',
    href: '/region/dellibertadorgeneralbernardoohiggins',
    icon: '/images/territories/ohiggins.svg',
    key: 't.r.dellibertadorgeneralbernardoohiggins',
    label: 'Libertador General Bernardo O’Higgins',
    order: 8,
    shortLabel: 'O’Higgins',
  },
  {
    acronym: 'ML',
    href: '/region/delmaule',
    icon: '/images/territories/maule.svg',
    key: 't.r.delmaule',
    label: 'Maule',
    order: 9,
    shortLabel: 'Maule',
  },
  {
    acronym: 'ÑB',
    href: '/region/denuble',
    icon: '/images/territories/nuble.svg',
    key: 't.r.denuble',
    label: 'Ñuble',
    order: 10,
    shortLabel: 'Ñuble',
  },
  {
    acronym: 'BI',
    href: '/region/delbiobio',
    icon: '/images/territories/biobio.svg',
    key: 't.r.delbiobio',
    label: 'Biobío',
    order: 11,
    shortLabel: 'Biobío',
  },
  {
    acronym: 'AR',
    href: '/region/delaaraucania',
    icon: '/images/territories/la_araucania.svg',
    key: 't.r.delaaraucania',
    label: 'La Araucanía',
    order: 12,
    shortLabel: 'La Araucanía',
  },
  {
    acronym: 'LR',
    href: '/region/delosrios',
    icon: '/images/territories/los_rios.svg',
    key: 't.r.delosrios',
    label: 'Los Ríos',
    order: 13,
    shortLabel: 'Los Ríos',
  },
  {
    acronym: 'LL',
    href: '/region/deloslagos',
    icon: '/images/territories/los_lagos.svg',
    key: 't.r.deloslagos',
    label: 'Los Lagos',
    order: 14,
    shortLabel: 'Los Lagos',
  },
  {
    acronym: 'AI',
    href: '/region/deaysendelgeneralcarlosibanezdelcampo',
    icon: '/images/territories/aysen.svg',
    key: 't.r.deaysendelgeneralcarlosibanezdelcampo',
    label: 'Aysén del General Carlos Ibánez del Campo',
    order: 15,
    shortLabel: 'Aysén',
  },
  {
    acronym: 'MA',
    href: '/region/demagallanesydelaantarticachilena',
    icon: '/images/territories/magallanes_y_ach.svg',
    key: 't.r.demagallanesydelaantarticachilena',
    label: 'Magallanes y de la Antártica Chilena',
    order: 16,
    shortLabel: 'Magallanes y ACh.',
  },
  {
    href: '/region/granvalparaiso',
    icon: '/images/territories/granvalparaiso.svg',
    isZone: true,
    key: 't.r.granvalparaiso',
    label: 'Gran Valparaíso',
    order: 18,
    shortLabel: 'Gran Valparaíso',
  },
  {
    href: '/region/gransantiago',
    icon: '/images/territories/gransantiago.svg',
    isZone: true,
    key: 't.r.gransantiago',
    label: 'Gran Santiago',
    order: 19,
    shortLabel: 'Gran Santiago',
  },
  {
    href: '/region/granconcepcion',
    icon: '/images/territories/granconcepcion.svg',
    isZone: true,
    key: 't.r.granconcepcion',
    label: 'Gran Concepción',
    order: 20,
    shortLabel: 'Gran Concepción',
  },
  {
    href: '/region/macrozonasur',
    icon: '/images/territories/macrozonasur.svg',
    isZone: true,
    key: 't.r.macrozonasur',
    label: 'Macrozona Sur',
    order: 21,
    shortLabel: 'Macrozona Sur',
  },
  {
    href: '/extranjero',
    icon: '/images/territories/extranjero.svg',
    isZone: true,
    key: 't.r.extranjero',
    label: 'Extranjero',
    order: 22,
    shortLabel: 'Extranjero',
  },
]

export const territoryMatch = (territory) => (
  zonesMapping.find((item) => item.key === territory)
)

export default zonesMapping
