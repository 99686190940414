import { useState } from "react"
import styled from "styled-components"

import { sorterHandler } from "components/2022/plebiscito/[regionId]/Communes/RegionSection/helpers"
import AtDecideChileBase from "components/2022/plebiscito/AtDecideChile"
import { ELECTIONS_TO_COMPARE, getTerritoryTableData, getZoneData } from "components/2022/plebiscito/RegionSection/helpers"
import SearchFilterBase from 'components/2022/plebiscito/RegionSection/SearchFilter'
import TitleHeaderBase from "components/2022/plebiscito/TitleHeader"
import { useEnvironment } from "components/Environment"
import { spaces } from 'components/global-parameters'
import RegionTable from "./RegionTable"

export default function RegionSection() {
  const [inputFilter, setInputFilter] = useState('')
  const [comparedElection, setComparedElection] = useState('pEntrada')
  const [selectedUnit, setSelectedUnit] = useState('porcentaje')
  const [sorter, setSorter] = useState({ field: 'order', order: 'asc' })
  const { electionsData: { pEntrada, pSalida, sVuelta } } = useEnvironment()

  const onChangeComparedElection = (newComparedElection) => {
    setComparedElection(comparedElection !== newComparedElection && newComparedElection)
  }
  const onChangeSelectedUnit = () => {
    setSelectedUnit(selectedUnit === 'porcentaje' ? 'cantidad' : 'porcentaje')
  }

  const onChangeSorter = ({ field }) => sorterHandler({ field, setSorter, sorter })

  const regionTableData = getTerritoryTableData({ comparedElection, inputFilter, pEntrada, pSalida, sVuelta, selectedUnit, sorter })
  const zoneTableData = getTerritoryTableData({ comparedElection, getTerritoryData: getZoneData, inputFilter, pEntrada, pSalida, sVuelta, selectedUnit, sorter })

  return (
    <Wrapper>
      <Top>
        <TitleHeader>Resultados de votos por región</TitleHeader>
      </Top>
      <Results>
        <SearchFilter
          comparedElection={comparedElection}
          electionOptions={ELECTIONS_TO_COMPARE}
          inputFilter={inputFilter}
          onChangeComparedElection={onChangeComparedElection}
          onChangeInputFilter={setInputFilter}
          onChangeSelectedUnit={onChangeSelectedUnit}
          onChangeSorter={onChangeSorter}
          selectedUnit={selectedUnit}
        />
        <AtDecideChile />
        <Tables>
          <RegionTable
            currentSort={sorter}
            data={regionTableData}
            onSort={onChangeSorter}
            selectedUnit={selectedUnit}
            territoryImage
          />
          <RegionTable
            currentSort={sorter}
            data={zoneTableData}
            firstColumnLabel={'Zona'}
            onSort={onChangeSorter}
            selectedUnit={selectedUnit}
            territoryImage
          />
        </Tables>
      </Results>
    </Wrapper>
  )
}

const Tables = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${spaces.l};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${spaces.s};
`

const Results = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
`

const Top = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: ${spaces.xxs};
`
const AtDecideChile = styled(AtDecideChileBase)`
  align-self: flex-end;
`
const TitleHeader = styled(TitleHeaderBase)``

const SearchFilter = styled(SearchFilterBase)`
  margin-top: 20px;
  margin-bottom: 20px;
`