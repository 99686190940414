import React from 'react'
import styled from 'styled-components'

import ComparedElectionVerboseBase from 'components/2022/plebiscito/Summary/ElectionBarVerbose/ComparedElectionVerbose'
import ProgressBar from 'components/2022/plebiscito/Summary/ProgressBar'
import { colors, spaces } from 'components/global-parameters'

export const ComparedElectionBarVerbose = ({ className, mobileLabel, options, shortLabel, totalVotes }) => {

  return (
    <Wrapper className={className}>
      <ComparedElectionVerbose
        optionLeft={options[0]}
        optionRight={options[1]}
        totalVotes={totalVotes}
      />
      <LabeledBar>
        <Label>
          <span>{shortLabel}</span>
          <span>{mobileLabel}</span>
        </Label>
        <ProgressBar
          height={'19px'}
          label={'votos'}
          labeled
          options={options.map(op => ({ color: op.color, count: op.cantidad, key: op.label }))}
          total={totalVotes}
        />
      </LabeledBar>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
`

const ComparedElectionVerbose = styled(ComparedElectionVerboseBase)`
  margin-top: 17px;
`

const Label = styled.span`
  font-family: inherit;
  font-weight: 400;
  font-size: 14em;
  line-height: 18px;
  color: ${colors.colorBlack};
  text-transform: capitalize;

  >:nth-child(2){
    display:none;
  }
  >:nth-child(1){
    display:inline;
  }
  @media(max-width: ${spaces.tabletBreakpoint}){
    >:nth-child(2){
      display:inline;
    }
    >:nth-child(1){
      display:none;
    }
  }
`

const LabeledBar = styled.div`
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -19px;
  gap: 5px;
`

export default ComparedElectionBarVerbose