import React from 'react'
import styled from "styled-components"

import StyledButton from 'components/2022/plebiscito/StyledButton'

export const MultiSelectionButton = ({className, options, selectedOption, setSelectedOption}) => {
    
    return (
      <Wrapper className={className}
        options={options}
      >
        {options.map(op => (
          <StyledButton disabledOption
            key={op.key}
            key_={op.key}
            label={op.label}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
            )
            )}
      </Wrapper>
    )
}


const Wrapper = styled.div`
    display: grid;
    grid: 1fr / repeat(${props => props.options.length}, 1fr);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    min-width: default;
`


export default MultiSelectionButton
