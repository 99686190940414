import React from 'react'
import styled from 'styled-components'

import NumberTextBase from 'components/2022/plebiscito/NumberText'
import { colors } from 'components/global-parameters'

export const ComparedElectionVerbose = ({ className, optionLeft, optionRight, totalVotes }) => {
  const ChoiceSummary = ({ align = 'left', color, label, voteFraction = null }) => (
    <NamePercentage align={align}>
      {voteFraction !== null && (
        <Percentage color={color}>
          <NumberText
            value={voteFraction * 100}
          />%
        </Percentage>
      )}
      <Name>{label}</Name>
    </NamePercentage>
  )

  return (
    <Wrapper className={className}>
      <ChoiceSummary
        color={optionLeft.color}
        key={optionLeft.label}
        label={optionLeft.shortLabel || optionLeft.label}
        voteFraction={totalVotes !== 0 ? optionLeft.porcentaje : 0}
      />
      <ChoiceSummary
        align={'right'}
        color={optionRight.color}
        key={optionRight.label}
        label={optionRight.shortLabel || optionRight.label}
        voteFraction={totalVotes !== 0 ? optionRight.porcentaje : 0}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr;
  width: 100%;
`

export const NamePercentage = styled.div`
  line-height: initial;
  display: flex;
  align-items: baseline;
  gap: 6em;
  justify-content: flex-start;
  flex-direction: ${(props) => props.align === 'right' && 'row-reverse'};
`

export const Name = styled.span`
  font-size: 14em;
  color: ${colors.colorBlack};
  font-weight: 300;
`

export const Percentage = styled.span`
  font-size: 16em;
  color: ${colors.colorBlack};
`

export const NumberText = styled(NumberTextBase)`
  color: ${({ color }) => color};
  font-weight: 700;
`



export default ComparedElectionVerbose
