import defaults from 'lodash/defaults'
import { parse } from 'papaparse'

import { colors } from 'components/global-parameters'

export const ELECTIONS_PROPERTIES = {
  pEntrada: {
    electionKey: 'pEntrada',
    label: '2020 Plebiscito',
    mobileLabel: '2020',
    optionA: {
      color: colors.colorBlack,
      field: 'APRUEBO',
      label: 'Apruebo'
    },
    optionB: {
      color: colors.colorGreyVersus,
      field: 'RECHAZO',
      label: 'Rechazo'
    },
    shortLabel: '2020 ENTRADA',
    totalVotantes: "TOTAL_VOTANTES"
  },
  pSalida: {
    electionKey: 'pSalida',
    label: 'Plebiscito de Salida',
    mobileLabel: '2022',
    optionA: {
      color: colors.colorBlueApruebo,
      field: 'APRUEBO',
      label: 'Apruebo'
    },
    optionB: {
      color: colors.colorRedRechazo,
      field: 'RECHAZO',
      label: 'Rechazo'
    },
    shortLabel: "2022 SALIDA",
    totalVotantes: "TOTAL_VOTANTES"
  },
  sVuelta: {
    electionKey: 'sVuelta',
    label: '2021 2da Vuelta',
    mobileLabel: '2021',
    optionA: {
      color: colors.colorBlack,
      field: 'GABRIEL_BORIC_FONT',
      label: 'Gabriel Boric',
      shortLabel: 'Boric'
    },
    optionB: {
      color: colors.colorGreyVersus,
      field: 'JOSE_ANTONIO_KAST_RIST',
      label: 'José Antonio Kast',
      shortLabel: 'Kast'
    },
    shortLabel: '2021 2DA VUELTA',
    totalVotantes: "TOTAL_VOTANTES"
  }
}

export const formatNumber = (number, options = {}) => {
  defaults(options, {
    decimalSeparator: ',',
    thousandSeparator: '.',
  })

  const string = number.toString()
  const parts = string.split(/[.,]/)
  const pattern = /(-?\d+)(\d{3})/
  let formatted = parts[0]

  while (pattern.test(formatted)) {
    formatted = formatted.replace(pattern, `$1${options.thousandSeparator}$2`)
  }

  if (parts[1]) {
    formatted += options.decimalSeparator
    formatted += parts[1]
  }

  return formatted
}

export const round = (number, decimals = 0) => {
  const pow = 10 ** decimals

  return parseFloat((Math.round(number * pow) / pow).toFixed(decimals))
}
export const between = (x, range) => {
  const [min, max] = range
  if (min > max) {
    throw RangeError("min > max")
  }
  return x >= min && x < max
}

export const parseCsv = (csv) => {

  const parseParams = {
    error: () => undefined,
    header: true,
    transform: (c) => isNaN(c) ? c : parseInt(c, 10)
  }

  const { data: jsonData } = parse(csv, parseParams)
  return jsonData
}

export const getRegionCommunes = ({ allElectionsData, regionId, zones }) => {
  const isCommuneZone = regionId == 'gransantiago' || regionId == 'granconcepcion' || regionId == 'granvalparaiso'
  if (regionId == 'macrozonasur') {
    return allElectionsData.filter(
      ({ N_KEY_REGION }) => (
        N_KEY_REGION === 't.r.deloslagos'
        || N_KEY_REGION === 't.r.delaaraucania'
        || N_KEY_REGION === 't.r.delosrios'
      )
    )
  } else if (isCommuneZone) {
    return allElectionsData.filter(c => zones[regionId].includes(c.N_KEY))
  } else {
    return allElectionsData.filter(c => c.N_KEY_REGION === `t.r.${regionId}`)
  }
}