import React from 'react'
import styled from "styled-components"

import StyledButton from 'components/2022/plebiscito/StyledButton'

export const ComparativeButton = ({ className, options }) => {

  return (
    <Wrapper className={className}>
      <Label>{'COMPARAR'}</Label>
      <Buttons size={options.length}>
        {options.map(op => (
          <StyledButton disabledOption
            key={op.key}
            key_={op.key}
            label={op.label}
            selectedOption={op.selectedOption}
            setSelectedOption={op.setSelectedOption}
          />
        ))}
      </Buttons>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
    display: grid;
    grid: 1fr / 1fr;

`

const Buttons = styled.div`
    display: grid;
    grid: 1fr / repeat(${props => props.size}, 1fr);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    min-width: default;
    gap: 10px;
`

const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 3px;
`


export default ComparativeButton