import React, { Fragment } from 'react'
import styled from "styled-components"

import Chart, { ChartBar, ChartBarCandidate } from 'components/2022/plebiscito/ElectionBar'
import { colors, spaces } from 'components/global-parameters'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import reportAnalytics from '../../reportAnalytics'

export const RegionItems = ({ className, href, icon, label, onClick, results, rowItem, selected, territoryImage }) => {

  const { pathname } = useRouter()

  const getChartResults = (result) => ([
    {
      ...result.optionA,
      vote_fraction: result.optionA.porcentaje
    },
    {
      ...result.optionB,
      vote_fraction: result.optionB.porcentaje
    }
  ])

  const sendSignal = (label, pathname, href) => {
    reportAnalytics('region_navegation', label, pathname, href)
    if (onClick) { onClick() }
  }

  const Zone = (
    <ZoneWrapper onClick={() => sendSignal(label, `${pathname}`, `${href}`)}>
      {territoryImage && (
        <ImageBox>
          <Image
            alt={label}
            height='40px'
            src={icon}
            width='40px'
          />
        </ImageBox>
      )}
      <RegionLabel>{label}</RegionLabel>
    </ZoneWrapper>
  )

  return (
    <>
      <Wrapper className={className}>
        {onClick ? (
          Zone
        ) : (
          <Link href={`${pathname}${href}`}
            passHref
          >{Zone}
          </Link>
        )}
        {Object.entries(results).map(([electionKey, result]) => {
          const chartResult = getChartResults(result)
          return (
            <Fragment key={electionKey + label}>
              <Label align={'row'} >
                {chartResult[0].label}
              </Label>
              <ElectionChart>
                <ElectionBar results={chartResult}
                  totalVotes={result.totalVotes}
                />
              </ElectionChart>
              <Label align={'row-reverse'} >
                {chartResult[1].label}
              </Label>
            </Fragment>
          )
        })}
      </Wrapper>
      {selected && rowItem && (
        <RowItem>
          {rowItem}
        </RowItem>
      )}</>
  )
}

const ElectionBar = styled(Chart)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${ChartBar} {
        ${ChartBarCandidate}{
            height: 25.82px;
        }
    }
`

const RowItem = styled.div`
  grid-column: 1/-1;
  min-height: 30px;
  background-color: white;
  z-index: 1;
`


const Wrapper = styled.div`
    display: contents;
    width: 100%;
    > * {
        padding: 10px 0;
        height: 100%;
        border-bottom: 0.5px solid ${colors.colorGrey15};
    }
    :hover{
        > * {
            background: ${colors.colorGrey10};
        }
    }
    @media(max-width: ${spaces.tabletBreakpoint}){
        > :nth-child(3n+1){
            border-right: 0.5px solid ${colors.colorBlack};
        }
        > :nth-child(1){
            box-shadow: -3px 19px 17px 0px ${colors.colorBlack};
        }
    }
`

const ImageBox = styled.div``

const ElectionChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    justify-content: flex-end;
    flex-direction: ${(props) => props.align};
    gap: 20px;
`

const ZoneWrapper = styled(Label)`
    display: grid;
    grid: 1fr / max-content 1fr;
    text-align: start;
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${colors.colorWhite};
    z-index: 1;
    gap: 20px;
    @media(max-width: ${spaces.tabletBreakpoint}){
        grid: 1fr / 1fr;
        min-width: 130px;
        ${ImageBox}{
            display:none;
        }
    }
`

const RegionLabel = styled.div`
    font-weight: 500;
    user-select: none;
    font-size: 13px;
    line-height: 17px;
    align-items: center;
    text-decoration-line: underline;
    text-transform: capitalize;
`

export default RegionItems


