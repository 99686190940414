import React from 'react'
import styled from "styled-components"

import { colors, spaces } from 'components/global-parameters'
import Image from 'next/image'

export const RegionHeader = ({ className, currentSort, firstColumnLabel, headers, onSort, selectedUnit, territoryImage, title }) => {
  const isCurrentSort = ({ currentSort, electionKey, field }) => {
    const { electionKey: currentElectionKey, field: currentField, order: currentOrder } = currentSort
    const isSameElection = electionKey === currentElectionKey
    const isSameField = currentField === field
    if (isSameElection && isSameField) {
      return currentOrder
    }
  }

  return (
    <Wrapper className={className}>
      { title && <><BlancItem /><ComparadorHeader headers={headers}>{title}</ComparadorHeader></>}
      <Zone onClick={() => onSort({ field: `order` })}
        territoryImage={territoryImage}
      >{firstColumnLabel}</Zone>
      {
        headers.map(({ electionKey, optionA, optionB, shortLabel }) => {
          return (
            <React.Fragment key={electionKey}>
              <LeftLabel
                onClick={() => onSort({ field: `results.${electionKey}.optionA.${selectedUnit}` })}
                order={isCurrentSort({ currentSort, electionKey, field: optionA.field })}
              >
                {optionA.shortLabel || optionA.label}
              </LeftLabel>
              <LabelWrapper>
                <b>{shortLabel}</b>
              </LabelWrapper>
              <RightLabel
                onClick={() => onSort({ field: `results.${electionKey}.optionB.${selectedUnit}` })}
                order={isCurrentSort({ currentSort, electionKey, field: optionB.field })}
              >
                {optionB.shortLabel || optionB.label}
              </RightLabel>
            </React.Fragment>
          )
        })
      }
    </Wrapper >
  )
}


const Label = ({ children, ...props }) => (
  <LabelWrapper {...props}>
    {children}
    <SortUpDown>
      <StyledImage alt={'sort_up'}
        height='7px'
        src={'/images/sorter_up.svg'}
        width='7px'
      />
      <StyledImage alt={'sort_down'}
        height='7px'
        src={'/images/sorter_down.svg'}
        width='7px'
      />
    </SortUpDown>
  </LabelWrapper>
)

const Wrapper = styled.div`
    display: contents;
    > * {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 0.5px solid ${colors.colorBlack};
    }
    @media(max-width: ${spaces.tabletBreakpoint}){
        > :nth-child(n + 4n+1){
            border-right: 0.5px solid ${colors.colorBlack};
        }
        &:nth-child(1){
            box-shadow: -3px 19px 17px 0px ${colors.colorBlack};
        }
    }
`

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    text-transform: uppercase;

    /* Disable select */
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
`

const BlancItem = styled(LabelWrapper)`
  border-bottom: 0.5px solid ${colors.colorWhite};
  position: sticky;
    top: 0;
    left: 0;
    background-color: ${colors.colorWhite};
    z-index: 1;
`

const ComparadorHeader = styled(LabelWrapper)`
  grid-column:  2 / ${({ headers }) => headers.length * 3 + 2};
`

const LeftLabel = styled(Label)`
    justify-content: flex-end;
    padding-right: 10px;
`
const RightLabel = styled(Label)`
    justify-content: flex-start;
    padding-left: 10px;
`

const Zone = styled(Label)`
    ${({ territoryImage }) => territoryImage && 'padding-left: 70px;'}
    justify-content: flex-start;
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${colors.colorWhite};
    z-index: 1;
    @media(max-width: ${spaces.tabletBreakpoint}){
        padding-left: 0px;
        > :nth-child(1){
            display:none;
        }
        grid: 1fr / 1fr;
        box-shadow: -3px 19px 17px 0px ${colors.colorBlack};
        border-right: 0.5px solid ${colors.colorBlack};
    }
`

const SortUpDown = styled.div`
    display: grid;
    grid: 1fr;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
    width: 7px;
`

const StyledImage = styled(Image)`
    cursor: pointer;
`

export default RegionHeader

