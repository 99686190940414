import _ from "lodash"

import { ELECTIONS_PROPERTIES } from "components/2022/plebiscito/helpers"


export const getResults = ({ isPreliminar = false,
  label,
  mobileLabel,
  optionA,
  optionB,
  ratioOptionA,
  ratioOptionB,
  ratioParticipation,
  shortLabel,
  total,
  totalCanVote,
  totalOptionA,
  totalOptionB,
  totalValid
}) => {
  const results = {
    isPreliminar,
    label,
    mobileLabel,
    options: [
      {
        cantidad: totalOptionA,
        color: optionA.color,
        label: optionA.shortLabel || optionA.label,
        porcentaje: ratioOptionA
      },
      {
        cantidad: totalOptionB,
        color: optionB.color,
        label: optionB.shortLabel || optionB.label,
        porcentaje: ratioOptionB
      }
    ],
    participation: {
      cantidad: total,
      porcentaje: ratioParticipation,
      totalVotes: totalCanVote
    },
    shortLabel: shortLabel || label,
    totalVotes: totalValid
  }
  return results
}

export const getVotesSummary = ({ electionData, optionA, optionB, totalVotantes }) => {
  const totalOptionA = _.sumBy(electionData, (territory) => (territory[optionA.field]) || 0)
  const totalOptionB = _.sumBy(electionData, (territory) => (territory[optionB.field]) || 0)
  const totalBlank = _.sumBy(electionData, (territory) => (territory.BLANCO) || 0)
  const totalNull = _.sumBy(electionData, (territory) => (territory.NULO) || 0)
  const totalCanVote = _.sumBy(electionData, (territory) => (territory[totalVotantes]) || 0)
  const totalValid = totalOptionA + totalOptionB
  const divisorTotalValid = totalValid === 0 ? 1 : totalValid
  const ratioOptionA = (totalOptionA / divisorTotalValid)
  const ratioOptionB = (totalOptionB / divisorTotalValid)
  const total = totalValid + totalBlank + totalNull
  const divisorTotal = total === 0 ? 1 : total
  const ratioParticipation = (total / divisorTotal)
  const colorA = optionA.color
  const colorB = optionB.color
  const isPreliminar = electionData.find(territory => territory.OFICIAL === 'false')
  return {
    colorA, colorB, isPreliminar, ratioOptionA, ratioOptionB, ratioParticipation, total, totalBlank, totalCanVote, totalNull, totalOptionA, totalOptionB, totalValid
  }
}

export const getElectionResults = ({ electionData, label, mobileLabel, optionA, optionB, shortLabel, totalVotantes }) => {
  const stats = getVotesSummary({ electionData, optionA, optionB, totalVotantes })
  const results = getResults({ ...stats, label, mobileLabel, optionA, optionB, shortLabel })
  results.options.sort((a, b) => b.cantidad - a.cantidad)
  return results
}

export const getElectionResultsBase = ({
  pEntrada,
  pSalida,
  sVuelta,
  territorySelector = getElectionResults
}) => {
  const pSalidaResults = territorySelector({
    electionData: [
      ...pSalida.nacional,
      ...pSalida.internacional
    ],
    ...ELECTIONS_PROPERTIES.pSalida
  })

  const pEntradaResults = territorySelector({
    electionData: [
      ...pEntrada.nacional,
      ...pEntrada.internacional
    ],
    ...ELECTIONS_PROPERTIES.pEntrada
  })

  const sVueltaResults = territorySelector({
    electionData: [
      ...sVuelta.nacional,
      ...sVuelta.internacional
    ],
    ...ELECTIONS_PROPERTIES.sVuelta
  })

  return {
    pEntrada: pEntradaResults,
    pSalida: pSalidaResults,
    sVuelta: sVueltaResults
  }
}

export const resultsWithElectionProperties = ({
  pEntrada,
  pSalida,
  selectedOptionPEntrada,
  selectedOptionSVuelta,
  setSelectedOptionPEntrada,
  setSelectedOptionSVuelta,
  sVuelta,
  territorySelector
}) => {

  const {
    pEntrada: pEntradaResults,
    pSalida: pSalidaResults,
    sVuelta: sVueltaResults
  } = getElectionResultsBase({ pEntrada, pSalida, sVuelta, territorySelector })

  const pSalidaSummary = {
    ...pSalidaResults,
  }

  const pEntradaSummary = {
    ...pEntradaResults,
    selectedOption: selectedOptionPEntrada,
    setSelectedOption: setSelectedOptionPEntrada
  }

  const sVueltaSummary = {
    ...sVueltaResults,
    selectedOption: selectedOptionSVuelta,
    setSelectedOption: setSelectedOptionSVuelta
  }

  return { pEntrada: pEntradaSummary, pSalida: pSalidaSummary, sVuelta: sVueltaSummary }
}

export const getSummaryData = (options) => Object.entries(options).map(([key, op]) => ({ key, ...op }))
