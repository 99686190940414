import styled, { css } from "styled-components"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import InternationalSummaryCard from "components/2022/plebiscito/Card"
import TitleHeaderBase from "components/2022/plebiscito/TitleHeader"
import { useEnvironment } from "components/Environment"
import { spaces } from "components/global-parameters"

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default function Highlighted({ className, isSkeleton = false }) {
  const { highlights } = useEnvironment()
  if (!highlights || !highlights.length) return null
  return (
    <Wrapper className={className}>
      <TitleHeader isSkeleton={isSkeleton}>
        Destacados
      </TitleHeader>
      <Swiper
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          780: {
            slidesPerView: 2,
          }
        }}
        modules={[Pagination, Navigation]}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        spaceBetween={10}
      >
        {
          highlights.map((result, index) => {
            return (
              <SwiperSlide key={index}>
                <InternationalSummaryCard isSkeleton={isSkeleton}
                  {...result}
                />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: ${spaces.m};
    .swiper {
      width: 100%;
      padding: 24px 0 36px 0;
    }
`
const skeleton = css`
  display: none;
`

const TitleHeader = styled(TitleHeaderBase)`
  ${({ isSkeleton }) => isSkeleton && skeleton}
`