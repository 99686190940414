import React from 'react'
import styled from "styled-components"

import YoutubeLiveVideo from 'components/2022/YoutubeLiveVideo'
import { spaces } from 'components/global-parameters'
import Image from 'next/image'
import Link from 'next/link'

export default function Header() {

  const youtubeLive = "lf3DpxtmkzY" /*TODO */

  return (
    <Wrapper>
      <Link href='/2022/plebiscito/'
        passHref
      >
        <a>
          <Image
            alt='decidechile'
            height='47px'
            src='/images/decidechile.svg'
            width='148px'
          />
        </a>
      </Link>
      <YoutubeLiveVideo
        videoId={youtubeLive}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${spaces.maxContent};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px;
`
