import React from 'react'
import styled from "styled-components"

import Layout2022 from 'components/2022/Layout'
import CountedTables from 'components/2022/plebiscito/CountedTables'
import HorizontalSeparator from 'components/2022/plebiscito/HorizontalSeparator'
import RegionSection from 'components/2022/plebiscito/RegionSection'
import Summary from 'components/2022/plebiscito/Summary'
import { spaces } from 'components/global-parameters'
import Head from 'next/head'
import DestacadosProvider from './iframe/DestacadosProvider'
import HighlightedBase from './iframe/Highlighted'


export default function Plebiscito() {
  return (
    <Wrapper>
      <Head>
        <title>Plebiscito de salida | Decidechile</title>
        <meta content='Resultados y estadísticas de las elecciones en tiempo real.'
          name='description'
        />
      </Head>
      <Summary />
      <HorizontalSeparator />
      <CountedTables />
      <HorizontalSeparator />
      <RegionSection />
      <HorizontalSeparator />
      <DestacadosProvider>
        <HighlightedContainer>
          <Highlighted />
        </HighlightedContainer>
      </DestacadosProvider>
    </Wrapper>
  )
}

const Wrapper = styled(Layout2022)`
  font-size: 1px;
  @media(max-width: ${spaces.tabletBreakpoint}){
    font-size: 0.9px;
  }
`

const HighlightedContainer = styled.div`
  max-width: 100%;
`

const Highlighted = styled(HighlightedBase)`
  padding: 0;
  gap: ${spaces.m};
`