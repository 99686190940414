import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { slide as BurgerMenu } from 'react-burger-menu'
import styled from "styled-components"

import { colors } from 'components/global-parameters'
import Image from 'next/image'


const years = ['2017', '2013', '2009', '2005', '2001', '1999', '1997', '1993', '1989']

export const Hamburger = ({ className }) => {

  return (
    <Wrapper className={className}>
      <BurgerMenu
        customBurgerIcon={<BurgerIcon text={'2022'} />}
        id='hamburger'
        isOpen={false}
        left
      >
        <MenuWrapper>
          <Menu>
            <ListTitle>Elecciones</ListTitle>
            <Events>
              <Table>
                <a href='https://2024.decidechile.cl'
                  rel='noreferrer'
                  target='_blank'
                >
                  <span>2024</span>
                </a>
                <a href='https://2023.decidechile.cl'
                  rel='noreferrer'
                  target='_blank'
                >
                  <span>2023</span>
                </a>
                <a href='https://2022.decidechile.cl'
                  rel='noreferrer'
                  target='_blank'
                >
                  <span>2022</span>
                </a>
                <a href='https://2021.decidechile.cl'
                  rel='noreferrer'
                  target='_blank'
                >
                  <span>2021</span>
                </a>
                <a
                  href='https://2020.decidechile.cl'
                  rel='noreferrer'
                  target='_blank'
                >
                  <span>2020</span>
                </a>
                {years.map(
                  (eventKey) => (
                    <a
                      href={`https://historico.decidechile.cl/#/ev/${eventKey}`}
                      key={eventKey}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <span>{eventKey}</span>
                    </a>
                  ),
                )}
              </Table>
            </Events>
            <Footer
              href='https://www.unholster.com/'
              target='_blank'
            >
              <Image
                alt='unholster'
                height='64px'
                src='/images/unholster-white-2021.svg'
                width='172px'
              />
            </Footer>
          </Menu>
        </MenuWrapper>
      </BurgerMenu>
    </Wrapper>
  )
}

const MenuWrapper = styled.div`
  height: 100%;
  top: 0;
  left: 0;
`

const Menu = styled.div`
  display: grid;
  grid: min-content auto min-content / 1fr;
  font-size: 10px;
  padding: 3.2em;
  gap: 3.2em;
  height: 100%;
  background-color: ${colors.decidechileBlue};
  color: white;
`

const BurgerIcon = ({ text }) => (
  <BurgerIconStyled>
    <FontAwesomeIcon height={14}
      icon={faBars}
      width={14}
    />
    <span>{text}</span>
  </BurgerIconStyled>
)

const BurgerIconStyled = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr;
  gap: 8px;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  color: ${colors.colorGrey8};
`

const ListTitle = styled.div`
  font-weight: bold;
  font-size: 1.6em;
  text-transform: uppercase;
`

const Footer = styled.a`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const Events = styled.div`
  padding-left: 24px;
  a, span {
      color: ${colors.colorGrey9};
      padding: 12px;

      &:hover {
        color: ${colors.colorWhite};
      }
    }
`

const Table = styled.div`
  display: grid;
  font-size: 1.6em;
  grid: 1fr / 1fr 1fr;
  align-items: start;
  gap: 0.5em;
`

const Wrapper = styled.div`
  .bm-burger-button {
    position: relative;
  }
  .bm-cross {
    background-color: ${colors.colorWhite};
  }
  .bm-menu-wrap {
    top: 0;
    left: 0;
  }

  .bm-overlay {
    left: 0;
    top: 0;
  }
`


export default Hamburger
