import React from "react"
import styled from "styled-components"

import { colors, percentFormatter } from "components/global-parameters"

export default function CardInfo({ label, percentage, ...props }) {
  const { format } = percentFormatter
  return (
    <Wrapper {...props}>
      <Title>{label.toLowerCase()}</Title>
      <Percentage>{format(percentage)}</Percentage>
      <Text>mesas escrutadas</Text>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 165px;
  background: white;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  gap: 6px;
  padding: 12px;
  font-family: JetBrains Mono;
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
`
const Percentage = styled(Title)`
  color: ${colors.decidechileBlue};
`

const Text = styled(Title)`
  font-size: 12px;
`