import _ from "lodash"

import zonesMapping from "components/2022/plebiscito/contextData/zonesMapping"
import { ELECTIONS_PROPERTIES, getRegionCommunes } from "components/2022/plebiscito/helpers"
import { percentFormatter } from "components/global-parameters"
import { zones as zonesData } from "../contextData/zonesData"
import { getVotesSummary } from "../Summary/helpers"

export const sorterHandler = ({ field, setSorter, sorter }) => {
  const changeOrder = sorter.field === field && sorter.order === 'asc'
  const newOrder = changeOrder ? 'desc' : 'asc'
  setSorter({ field: `${field}`, order: newOrder })
}

export const ELECTIONS_TO_COMPARE = [ELECTIONS_PROPERTIES.pEntrada, ELECTIONS_PROPERTIES.sVuelta]
export const getValue = (object, path) => {
  const value = _.get(object, path)
  if (isNaN(value)) {
    return value
  }
  return Number(value)
}

export const normalizeText = (str) => str?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const getElectionsToRemove = ({ comparedElection }) => {
  const comparedElections = [...ELECTIONS_TO_COMPARE]
  const selectedElectionIndex = comparedElections.findIndex(election => {
    return election.electionKey === comparedElection
  })
  selectedElectionIndex !== -1 && comparedElections.splice(selectedElectionIndex, 1)
  return comparedElections
}

export const formatPercentage = (str) => {
  const { format } = percentFormatter
  return format(str)
}

export const formatCantidad = (int) => {
  return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const getTerritoriesResult = (dataByTerritory) => {
  const { optionA, optionB, totalVotantes } = ELECTIONS_PROPERTIES[dataByTerritory[0].electionKey]
  const stats = getVotesSummary({ electionData: dataByTerritory, optionA, optionB, totalVotantes })
  const { colorA, colorB, ratioOptionA, ratioOptionB, totalOptionA, totalOptionB, totalValid } = stats
  return {
    optionA: {
      cantidad: totalOptionA,
      color: colorA,
      porcentaje: ratioOptionA
    },
    optionB: {
      cantidad: totalOptionB,
      color: colorB,
      porcentaje: ratioOptionB
    },
    totalVotes: totalValid,
  }
}

const resultsByelection = ({ data }) => {
  return data.map(({ electionsData, regionKey }) => ({
    ...zonesMapping.find(({ key }) => key === regionKey),
    results: Object.entries(electionsData).reduce(
      (resultsByRegion, [key, territories]) => {
        resultsByRegion[key] = getTerritoriesResult(territories)
        return resultsByRegion
      }, {})
  }))
}

export const sortTable = ({ data, sorter }) => data.sort((t1, t2) => {
  const firstValue = getValue(t1, sorter.field)
  const secondValue = getValue(t2, sorter.field)
  const numberSorter = typeof firstValue === 'number' && typeof secondValue === 'number'
  const comparison = numberSorter 
    ? Number.parseFloat(firstValue) - Number.parseFloat(secondValue) 
    : String(firstValue).localeCompare(
        String(secondValue),
        undefined,
        { ignorePunctuation: true, numeric: true, sensitivity: 'base', usage: 'sort' }
      )
  return sorter.order === 'desc' ? -comparison : comparison
})

const formatters = {
  'cantidad': formatCantidad,
  'porcentaje': formatPercentage
}

export const filterTable = ({ comparedElection, data, inputFilter, selectedUnit }) => {
  const electionsToRemove = getElectionsToRemove({ comparedElection })
  const zoneFilteredData = data.filter(t => normalizeText(t.label)?.includes(normalizeText(inputFilter)))
  zoneFilteredData.forEach(t => {
    const formatter = formatters[selectedUnit]
    Object.values(electionsToRemove)
      .forEach(
        ({ electionKey }) => delete t.results[electionKey]
      )
    Object.values(t.results)
      .forEach(r => {
        r.optionA.label = formatter(r.optionA[selectedUnit])
        r.optionB.label = formatter(r.optionB[selectedUnit])
      })
  })
  return zoneFilteredData
}

export const getAllData = ({ pEntrada, pSalida, sVuelta }) => {
  return [
    ...[...pSalida.nacional, ...pSalida.internacional].map(t => ({ ...t, electionKey: ELECTIONS_PROPERTIES.pSalida.electionKey })),
    ...[...pEntrada.nacional, ...pEntrada.internacional].map(t => ({ ...t, electionKey: ELECTIONS_PROPERTIES.pEntrada.electionKey })),
    ...[...sVuelta.nacional, ...sVuelta.internacional].map(t => ({ ...t, electionKey: ELECTIONS_PROPERTIES.sVuelta.electionKey }))
  ]
}

const regionData = ({ allElectionsData }) => {
  const dataByRegion = _.groupBy(allElectionsData, ({ N_KEY_REGION }) => N_KEY_REGION || 't.r.extranjero')
  delete dataByRegion['t.r.extranjero']
  return dataByRegion
}

export const getZoneData = ({ allElectionsData }) => {
  const zones = zonesMapping.filter(z => z.isZone)
  const dataByZone = zones.reduce((acc, zone) => {
    acc[zone.key] = getRegionCommunes({ allElectionsData, regionId: zone.key.replace('t.r.', ''), zones: zonesData })
    return acc
  }, {})
  const extranjeroData = allElectionsData.filter(({ N_KEY_REGION }) => !N_KEY_REGION)
  dataByZone['t.r.extranjero'] = extranjeroData
  return dataByZone
}

export const getTerritoryTableData = ({ comparedElection, getTerritoryData = regionData, inputFilter, pEntrada, pSalida, selectedUnit, sorter, sVuelta }) => {
  const allElectionsData = getAllData({ pEntrada, pSalida, sVuelta })
  const dataByRegion = getTerritoryData({ allElectionsData })
  const dataByElection = Object.entries(dataByRegion).map(([regionKey, territories]) => ({ electionsData: _.groupBy(territories, 'electionKey'), regionKey }))
  const results = resultsByelection({ data: dataByElection })
  const sortedData = sortTable({ data: results, sorter })
  const filteredData = filterTable({ comparedElection, data: sortedData, inputFilter, selectedUnit })
  return filteredData
}
