import { parseCsv } from "components/2022/plebiscito/helpers"

export const territoriesCsv = `n_key_comuna,n_key_region,cod_comuna,cod_region,nombre_comuna,nombre_region,label
t.c.iquique,t.r.detarapaca,2501,3001,IQUIQUE,DE TARAPACÁ,IQUIQUE
t.c.altohospicio,t.r.detarapaca,2502,3001,ALTO HOSPICIO,DE TARAPACÁ,ALTO HOSPICIO
t.c.pozoalmonte,t.r.detarapaca,2503,3001,POZO ALMONTE,DE TARAPACÁ,POZO ALMONTE
t.c.camina,t.r.detarapaca,2504,3001,CAMIÑA,DE TARAPACÁ,CAMIÑA
t.c.colchane,t.r.detarapaca,2505,3001,COLCHANE,DE TARAPACÁ,COLCHANE
t.c.huara,t.r.detarapaca,2506,3001,HUARA,DE TARAPACÁ,HUARA
t.c.pica,t.r.detarapaca,2507,3001,PICA,DE TARAPACÁ,PICA
t.c.antofagasta,t.r.deantofagasta,2508,3002,ANTOFAGASTA,DE ANTOFAGASTA,ANTOFAGASTA
t.c.mejillones,t.r.deantofagasta,2509,3002,MEJILLONES,DE ANTOFAGASTA,MEJILLONES
t.c.sierragorda,t.r.deantofagasta,2510,3002,SIERRA GORDA,DE ANTOFAGASTA,SIERRA GORDA
t.c.taltal,t.r.deantofagasta,2511,3002,TALTAL,DE ANTOFAGASTA,TALTAL
t.c.calama,t.r.deantofagasta,2512,3002,CALAMA,DE ANTOFAGASTA,CALAMA
t.c.ollague,t.r.deantofagasta,2513,3002,OLLAGUE,DE ANTOFAGASTA,OLLAGÜE
t.c.sanpedrodeatacama,t.r.deantofagasta,2514,3002,SAN PEDRO DE ATACAMA,DE ANTOFAGASTA,SAN PEDRO DE ATACAMA
t.c.tocopilla,t.r.deantofagasta,2515,3002,TOCOPILLA,DE ANTOFAGASTA,TOCOPILLA
t.c.mariaelena,t.r.deantofagasta,2516,3002,MARIA ELENA,DE ANTOFAGASTA,MARÍA ELENA
t.c.copiapo,t.r.deatacama,2517,3003,COPIAPO,DE ATACAMA,COPIAPÓ
t.c.caldera,t.r.deatacama,2518,3003,CALDERA,DE ATACAMA,CALDERA
t.c.tierraamarilla,t.r.deatacama,2519,3003,TIERRA AMARILLA,DE ATACAMA,TIERRA AMARILLA
t.c.chanaral,t.r.deatacama,2520,3003,CHAÑARAL,DE ATACAMA,CHAÑARAL
t.c.diegodealmagro,t.r.deatacama,2521,3003,DIEGO DE ALMAGRO,DE ATACAMA,DIEGO DE ALMAGRO
t.c.vallenar,t.r.deatacama,2522,3003,VALLENAR,DE ATACAMA,VALLENAR
t.c.altodelcarmen,t.r.deatacama,2523,3003,ALTO DEL CARMEN,DE ATACAMA,ALTO DEL CARMEN
t.c.freirina,t.r.deatacama,2524,3003,FREIRINA,DE ATACAMA,FREIRINA
t.c.huasco,t.r.deatacama,2525,3003,HUASCO,DE ATACAMA,HUASCO
t.c.laserena,t.r.decoquimbo,2526,3004,LA SERENA,DE COQUIMBO,LA SERENA
t.c.coquimbo,t.r.decoquimbo,2527,3004,COQUIMBO,DE COQUIMBO,COQUIMBO
t.c.andacollo,t.r.decoquimbo,2528,3004,ANDACOLLO,DE COQUIMBO,ANDACOLLO
t.c.lahiguera,t.r.decoquimbo,2529,3004,LA HIGUERA,DE COQUIMBO,LA HIGUERA
t.c.paihuano,t.r.decoquimbo,2530,3004,PAIGUANO,DE COQUIMBO,PAIHUANO
t.c.vicuna,t.r.decoquimbo,2531,3004,VICUÑA,DE COQUIMBO,VICUÑA
t.c.illapel,t.r.decoquimbo,2532,3004,ILLAPEL,DE COQUIMBO,ILLAPEL
t.c.canela,t.r.decoquimbo,2533,3004,CANELA,DE COQUIMBO,CANELA
t.c.losvilos,t.r.decoquimbo,2534,3004,LOS VILOS,DE COQUIMBO,LOS VILOS
t.c.salamanca,t.r.decoquimbo,2535,3004,SALAMANCA,DE COQUIMBO,SALAMANCA
t.c.ovalle,t.r.decoquimbo,2536,3004,OVALLE,DE COQUIMBO,OVALLE
t.c.combarbala,t.r.decoquimbo,2537,3004,COMBARBALA,DE COQUIMBO,COMBARBALÁ
t.c.montepatria,t.r.decoquimbo,2538,3004,MONTE PATRIA,DE COQUIMBO,MONTE PATRIA
t.c.punitaqui,t.r.decoquimbo,2539,3004,PUNITAQUI,DE COQUIMBO,PUNITAQUI
t.c.riohurtado,t.r.decoquimbo,2540,3004,RIO HURTADO,DE COQUIMBO,RÍO HURTADO
t.c.valparaiso,t.r.devalparaiso,2541,3005,VALPARAISO,DE VALPARAÍSO,VALPARAÍSO
t.c.casablanca,t.r.devalparaiso,2542,3005,CASABLANCA,DE VALPARAÍSO,CASABLANCA
t.c.concon,t.r.devalparaiso,2543,3005,CONCON,DE VALPARAÍSO,CONCÓN
t.c.juanfernandez,t.r.devalparaiso,2544,3005,JUAN FERNANDEZ,DE VALPARAÍSO,JUAN FERNÁNDEZ
t.c.puchuncavi,t.r.devalparaiso,2545,3005,PUCHUNCAVI,DE VALPARAÍSO,PUCHUNCAVÍ
t.c.quintero,t.r.devalparaiso,2546,3005,QUINTERO,DE VALPARAÍSO,QUINTERO
t.c.vinadelmar,t.r.devalparaiso,2547,3005,VIÑA DEL MAR,DE VALPARAÍSO,VIÑA DEL MAR
t.c.isladepascua,t.r.devalparaiso,2548,3005,ISLA DE PASCUA,DE VALPARAÍSO,ISLA DE PASCUA
t.c.losandes,t.r.devalparaiso,2549,3005,LOS ANDES,DE VALPARAÍSO,LOS ANDES
t.c.callelarga,t.r.devalparaiso,2550,3005,CALLE LARGA,DE VALPARAÍSO,CALLE LARGA
t.c.rinconada,t.r.devalparaiso,2551,3005,RINCONADA,DE VALPARAÍSO,RINCONADA
t.c.sanesteban,t.r.devalparaiso,2552,3005,SAN ESTEBAN,DE VALPARAÍSO,SAN ESTEBAN
t.c.laligua,t.r.devalparaiso,2553,3005,LA LIGUA,DE VALPARAÍSO,LA LIGUA
t.c.cabildo,t.r.devalparaiso,2554,3005,CABILDO,DE VALPARAÍSO,CABILDO
t.c.papudo,t.r.devalparaiso,2555,3005,PAPUDO,DE VALPARAÍSO,PAPUDO
t.c.petorca,t.r.devalparaiso,2556,3005,PETORCA,DE VALPARAÍSO,PETORCA
t.c.zapallar,t.r.devalparaiso,2557,3005,ZAPALLAR,DE VALPARAÍSO,ZAPALLAR
t.c.quillota,t.r.devalparaiso,2558,3005,QUILLOTA,DE VALPARAÍSO,QUILLOTA
t.c.calera,t.r.devalparaiso,2559,3005,CALERA,DE VALPARAÍSO,CALERA
t.c.hijuelas,t.r.devalparaiso,2560,3005,HIJUELAS,DE VALPARAÍSO,HIJUELAS
t.c.lacruz,t.r.devalparaiso,2561,3005,LA CRUZ,DE VALPARAÍSO,LA CRUZ
t.c.nogales,t.r.devalparaiso,2562,3005,NOGALES,DE VALPARAÍSO,NOGALES
t.c.sanantonio,t.r.devalparaiso,2563,3005,SAN ANTONIO,DE VALPARAÍSO,SAN ANTONIO
t.c.algarrobo,t.r.devalparaiso,2564,3005,ALGARROBO,DE VALPARAÍSO,ALGARROBO
t.c.cartagena,t.r.devalparaiso,2565,3005,CARTAGENA,DE VALPARAÍSO,CARTAGENA
t.c.elquisco,t.r.devalparaiso,2566,3005,EL QUISCO,DE VALPARAÍSO,EL QUISCO
t.c.eltabo,t.r.devalparaiso,2567,3005,EL TABO,DE VALPARAÍSO,EL TABO
t.c.santodomingo,t.r.devalparaiso,2568,3005,SANTO DOMINGO,DE VALPARAÍSO,SANTO DOMINGO
t.c.sanfelipe,t.r.devalparaiso,2569,3005,SAN FELIPE,DE VALPARAÍSO,SAN FELIPE
t.c.catemu,t.r.devalparaiso,2570,3005,CATEMU,DE VALPARAÍSO,CATEMU
t.c.llaillay,t.r.devalparaiso,2571,3005,LLAY-LLAY,DE VALPARAÍSO,LLAY-LLAY
t.c.panquehue,t.r.devalparaiso,2572,3005,PANQUEHUE,DE VALPARAÍSO,PANQUEHUE
t.c.putaendo,t.r.devalparaiso,2573,3005,PUTAENDO,DE VALPARAÍSO,PUTAENDO
t.c.santamaria,t.r.devalparaiso,2574,3005,SANTA MARIA,DE VALPARAÍSO,SANTA MARÍA
t.c.quilpue,t.r.devalparaiso,2575,3005,QUILPUE,DE VALPARAÍSO,QUILPUÉ
t.c.limache,t.r.devalparaiso,2576,3005,LIMACHE,DE VALPARAÍSO,LIMACHE
t.c.olmue,t.r.devalparaiso,2577,3005,OLMUE,DE VALPARAÍSO,OLMUÉ
t.c.villaalemana,t.r.devalparaiso,2578,3005,VILLA ALEMANA,DE VALPARAÍSO,VILLA ALEMANA
t.c.rancagua,t.r.dellibertadorgeneralbernardoohiggins,2579,3006,RANCAGUA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,RANCAGUA
t.c.codegua,t.r.dellibertadorgeneralbernardoohiggins,2580,3006,CODEGUA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,CODEGUA
t.c.coinco,t.r.dellibertadorgeneralbernardoohiggins,2581,3006,COINCO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,COINCO
t.c.coltauco,t.r.dellibertadorgeneralbernardoohiggins,2582,3006,COLTAUCO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,COLTAUCO
t.c.donihue,t.r.dellibertadorgeneralbernardoohiggins,2583,3006,DOÑIHUE,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,DOÑIHUE
t.c.graneros,t.r.dellibertadorgeneralbernardoohiggins,2584,3006,GRANEROS,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,GRANEROS
t.c.lascabras,t.r.dellibertadorgeneralbernardoohiggins,2585,3006,LAS CABRAS,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,LAS CABRAS
t.c.machali,t.r.dellibertadorgeneralbernardoohiggins,2586,3006,MACHALI,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,MACHALÍ
t.c.malloa,t.r.dellibertadorgeneralbernardoohiggins,2587,3006,MALLOA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,MALLOA
t.c.mostazal,t.r.dellibertadorgeneralbernardoohiggins,2588,3006,MOSTAZAL,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,MOSTAZAL
t.c.olivar,t.r.dellibertadorgeneralbernardoohiggins,2589,3006,OLIVAR,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,OLIVAR
t.c.peumo,t.r.dellibertadorgeneralbernardoohiggins,2590,3006,PEUMO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PEUMO
t.c.pichidegua,t.r.dellibertadorgeneralbernardoohiggins,2591,3006,PICHIDEGUA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PICHIDEGUA
t.c.quintadetilcoco,t.r.dellibertadorgeneralbernardoohiggins,2592,3006,QUINTA DE TILCOCO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,QUINTA DE TILCOCO
t.c.rengo,t.r.dellibertadorgeneralbernardoohiggins,2593,3006,RENGO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,RENGO
t.c.requinoa,t.r.dellibertadorgeneralbernardoohiggins,2594,3006,REQUINOA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,REQUÍNOA
t.c.sanvicente,t.r.dellibertadorgeneralbernardoohiggins,2595,3006,SAN VICENTE,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,SAN VICENTE
t.c.pichilemu,t.r.dellibertadorgeneralbernardoohiggins,2596,3006,PICHILEMU,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PICHILEMU
t.c.laestrella,t.r.dellibertadorgeneralbernardoohiggins,2597,3006,LA ESTRELLA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,LA ESTRELLA
t.c.litueche,t.r.dellibertadorgeneralbernardoohiggins,2598,3006,LITUECHE,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,LITUECHE
t.c.marchigue,t.r.dellibertadorgeneralbernardoohiggins,2599,3006,MARCHIGUE,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,MARCHIGÜE
t.c.navidad,t.r.dellibertadorgeneralbernardoohiggins,2600,3006,NAVIDAD,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,NAVIDAD
t.c.paredones,t.r.dellibertadorgeneralbernardoohiggins,2601,3006,PAREDONES,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PAREDONES
t.c.sanfernando,t.r.dellibertadorgeneralbernardoohiggins,2602,3006,SAN FERNANDO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,SAN FERNANDO
t.c.chepica,t.r.dellibertadorgeneralbernardoohiggins,2603,3006,CHEPICA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,CHÉPICA
t.c.chimbarongo,t.r.dellibertadorgeneralbernardoohiggins,2604,3006,CHIMBARONGO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,CHIMBARONGO
t.c.lolol,t.r.dellibertadorgeneralbernardoohiggins,2605,3006,LOLOL,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,LOLOL
t.c.nancagua,t.r.dellibertadorgeneralbernardoohiggins,2606,3006,NANCAGUA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,NANCAGUA
t.c.palmilla,t.r.dellibertadorgeneralbernardoohiggins,2607,3006,PALMILLA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PALMILLA
t.c.peralillo,t.r.dellibertadorgeneralbernardoohiggins,2608,3006,PERALILLO,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PERALILLO
t.c.placilla,t.r.dellibertadorgeneralbernardoohiggins,2609,3006,PLACILLA,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PLACILLA
t.c.pumanque,t.r.dellibertadorgeneralbernardoohiggins,2610,3006,PUMANQUE,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,PUMANQUE
t.c.santacruz,t.r.dellibertadorgeneralbernardoohiggins,2611,3006,SANTA CRUZ,DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS,SANTA CRUZ
t.c.talca,t.r.delmaule,2612,3007,TALCA,DEL MAULE,TALCA
t.c.constitucion,t.r.delmaule,2613,3007,CONSTITUCION,DEL MAULE,CONSTITUCIÓN
t.c.curepto,t.r.delmaule,2614,3007,CUREPTO,DEL MAULE,CUREPTO
t.c.empedrado,t.r.delmaule,2615,3007,EMPEDRADO,DEL MAULE,EMPEDRADO
t.c.maule,t.r.delmaule,2616,3007,MAULE,DEL MAULE,MAULE
t.c.pelarco,t.r.delmaule,2617,3007,PELARCO,DEL MAULE,PELARCO
t.c.pencahue,t.r.delmaule,2618,3007,PENCAHUE,DEL MAULE,PENCAHUE
t.c.rioclaro,t.r.delmaule,2619,3007,RIO CLARO,DEL MAULE,RÍO CLARO
t.c.sanclemente,t.r.delmaule,2620,3007,SAN CLEMENTE,DEL MAULE,SAN CLEMENTE
t.c.sanrafael,t.r.delmaule,2621,3007,SAN RAFAEL,DEL MAULE,SAN RAFAEL
t.c.cauquenes,t.r.delmaule,2622,3007,CAUQUENES,DEL MAULE,CAUQUENES
t.c.chanco,t.r.delmaule,2623,3007,CHANCO,DEL MAULE,CHANCO
t.c.pelluhue,t.r.delmaule,2624,3007,PELLUHUE,DEL MAULE,PELLUHUE
t.c.curico,t.r.delmaule,2625,3007,CURICO,DEL MAULE,CURICÓ
t.c.hualane,t.r.delmaule,2626,3007,HUALAÑE,DEL MAULE,HUALAÑE
t.c.licanten,t.r.delmaule,2627,3007,LICANTEN,DEL MAULE,LICANTÉN
t.c.molina,t.r.delmaule,2628,3007,MOLINA,DEL MAULE,MOLINA
t.c.rauco,t.r.delmaule,2629,3007,RAUCO,DEL MAULE,RAUCO
t.c.romeral,t.r.delmaule,2630,3007,ROMERAL,DEL MAULE,ROMERAL
t.c.sagradafamilia,t.r.delmaule,2631,3007,SAGRADA FAMILIA,DEL MAULE,SAGRADA FAMILIA
t.c.teno,t.r.delmaule,2632,3007,TENO,DEL MAULE,TENO
t.c.vichuquen,t.r.delmaule,2633,3007,VICHUQUEN,DEL MAULE,VICHUQUÉN
t.c.linares,t.r.delmaule,2634,3007,LINARES,DEL MAULE,LINARES
t.c.colbun,t.r.delmaule,2635,3007,COLBUN,DEL MAULE,COLBÚN
t.c.longavi,t.r.delmaule,2636,3007,LONGAVI,DEL MAULE,LONGAVÍ
t.c.parral,t.r.delmaule,2637,3007,PARRAL,DEL MAULE,PARRAL
t.c.retiro,t.r.delmaule,2638,3007,RETIRO,DEL MAULE,RETIRO
t.c.sanjavier,t.r.delmaule,2639,3007,SAN JAVIER,DEL MAULE,SAN JAVIER
t.c.villaalegre,t.r.delmaule,2640,3007,VILLA ALEGRE,DEL MAULE,VILLA ALEGRE
t.c.yerbasbuenas,t.r.delmaule,2641,3007,YERBAS BUENAS,DEL MAULE,YERBAS BUENAS
t.c.concepcion,t.r.delbiobio,2642,3008,CONCEPCION,DEL BIOBÍO,CONCEPCIÓN
t.c.coronel,t.r.delbiobio,2643,3008,CORONEL,DEL BIOBÍO,CORONEL
t.c.chiguayante,t.r.delbiobio,2644,3008,CHIGUAYANTE,DEL BIOBÍO,CHIGUAYANTE
t.c.florida,t.r.delbiobio,2645,3008,FLORIDA,DEL BIOBÍO,FLORIDA
t.c.hualqui,t.r.delbiobio,2646,3008,HUALQUI,DEL BIOBÍO,HUALQUI
t.c.lota,t.r.delbiobio,2647,3008,LOTA,DEL BIOBÍO,LOTA
t.c.penco,t.r.delbiobio,2648,3008,PENCO,DEL BIOBÍO,PENCO
t.c.sanpedrodelapaz,t.r.delbiobio,2649,3008,SAN PEDRO DE LA PAZ,DEL BIOBÍO,SAN PEDRO DE LA PAZ
t.c.santajuana,t.r.delbiobio,2650,3008,SANTA JUANA,DEL BIOBÍO,SANTA JUANA
t.c.talcahuano,t.r.delbiobio,2651,3008,TALCAHUANO,DEL BIOBÍO,TALCAHUANO
t.c.tome,t.r.delbiobio,2652,3008,TOME,DEL BIOBÍO,TOMÉ
t.c.hualpen,t.r.delbiobio,2653,3008,HUALPEN,DEL BIOBÍO,HUALPÉN
t.c.lebu,t.r.delbiobio,2654,3008,LEBU,DEL BIOBÍO,LEBU
t.c.arauco,t.r.delbiobio,2655,3008,ARAUCO,DEL BIOBÍO,ARAUCO
t.c.canete,t.r.delbiobio,2656,3008,CAÑETE,DEL BIOBÍO,CAÑETE
t.c.contulmo,t.r.delbiobio,2657,3008,CONTULMO,DEL BIOBÍO,CONTULMO
t.c.curanilahue,t.r.delbiobio,2658,3008,CURANILAHUE,DEL BIOBÍO,CURANILAHUE
t.c.losalamos,t.r.delbiobio,2659,3008,LOS ALAMOS,DEL BIOBÍO,LOS ALAMOS
t.c.tirua,t.r.delbiobio,2660,3008,TIRUA,DEL BIOBÍO,TIRÚA
t.c.losangeles,t.r.delbiobio,2661,3008,LOS ANGELES,DEL BIOBÍO,LOS ÁNGELES
t.c.antuco,t.r.delbiobio,2662,3008,ANTUCO,DEL BIOBÍO,ANTUCO
t.c.cabrero,t.r.delbiobio,2663,3008,CABRERO,DEL BIOBÍO,CABRERO
t.c.laja,t.r.delbiobio,2664,3008,LAJA,DEL BIOBÍO,LAJA
t.c.mulchen,t.r.delbiobio,2665,3008,MULCHEN,DEL BIOBÍO,MULCHÉN
t.c.nacimiento,t.r.delbiobio,2666,3008,NACIMIENTO,DEL BIOBÍO,NACIMIENTO
t.c.negrete,t.r.delbiobio,2667,3008,NEGRETE,DEL BIOBÍO,NEGRETE
t.c.quilaco,t.r.delbiobio,2668,3008,QUILACO,DEL BIOBÍO,QUILACO
t.c.quilleco,t.r.delbiobio,2669,3008,QUILLECO,DEL BIOBÍO,QUILLECO
t.c.sanrosendo,t.r.delbiobio,2670,3008,SAN ROSENDO,DEL BIOBÍO,SAN ROSENDO
t.c.santabarbara,t.r.delbiobio,2671,3008,SANTA BARBARA,DEL BIOBÍO,SANTA BÁRBARA
t.c.tucapel,t.r.delbiobio,2672,3008,TUCAPEL,DEL BIOBÍO,TUCAPEL
t.c.yumbel,t.r.delbiobio,2673,3008,YUMBEL,DEL BIOBÍO,YUMBEL
t.c.altobiobio,t.r.delbiobio,2674,3008,ALTO BIOBIO,DEL BIOBÍO,ALTO BIOBIO
t.c.temuco,t.r.delaaraucania,2675,3009,TEMUCO,DE LA ARAUCANÍA,TEMUCO
t.c.carahue,t.r.delaaraucania,2676,3009,CARAHUE,DE LA ARAUCANÍA,CARAHUE
t.c.cunco,t.r.delaaraucania,2677,3009,CUNCO,DE LA ARAUCANÍA,CUNCO
t.c.curarrehue,t.r.delaaraucania,2678,3009,CURARREHUE,DE LA ARAUCANÍA,CURARREHUE
t.c.freire,t.r.delaaraucania,2679,3009,FREIRE,DE LA ARAUCANÍA,FREIRE
t.c.galvarino,t.r.delaaraucania,2680,3009,GALVARINO,DE LA ARAUCANÍA,GALVARINO
t.c.gorbea,t.r.delaaraucania,2681,3009,GORBEA,DE LA ARAUCANÍA,GORBEA
t.c.lautaro,t.r.delaaraucania,2682,3009,LAUTARO,DE LA ARAUCANÍA,LAUTARO
t.c.loncoche,t.r.delaaraucania,2683,3009,LONCOCHE,DE LA ARAUCANÍA,LONCOCHE
t.c.melipeuco,t.r.delaaraucania,2684,3009,MELIPEUCO,DE LA ARAUCANÍA,MELIPEUCO
t.c.nuevaimperial,t.r.delaaraucania,2685,3009,NUEVA IMPERIAL,DE LA ARAUCANÍA,NUEVA IMPERIAL
t.c.padrelascasas,t.r.delaaraucania,2686,3009,PADRE LAS CASAS,DE LA ARAUCANÍA,PADRE LAS CASAS
t.c.perquenco,t.r.delaaraucania,2687,3009,PERQUENCO,DE LA ARAUCANÍA,PERQUENCO
t.c.pitrufquen,t.r.delaaraucania,2688,3009,PITRUFQUEN,DE LA ARAUCANÍA,PITRUFQUÉN
t.c.pucon,t.r.delaaraucania,2689,3009,PUCON,DE LA ARAUCANÍA,PUCÓN
t.c.saavedra,t.r.delaaraucania,2690,3009,SAAVEDRA,DE LA ARAUCANÍA,SAAVEDRA
t.c.teodoroschmidt,t.r.delaaraucania,2691,3009,TEODORO SCHMIDT,DE LA ARAUCANÍA,TEODORO SCHMIDT
t.c.tolten,t.r.delaaraucania,2692,3009,TOLTEN,DE LA ARAUCANÍA,TOLTÉN
t.c.vilcun,t.r.delaaraucania,2693,3009,VILCUN,DE LA ARAUCANÍA,VILCÚN
t.c.villarrica,t.r.delaaraucania,2694,3009,VILLARRICA,DE LA ARAUCANÍA,VILLARRICA
t.c.cholchol,t.r.delaaraucania,2695,3009,CHOLCHOL,DE LA ARAUCANÍA,CHOLCHOL
t.c.angol,t.r.delaaraucania,2696,3009,ANGOL,DE LA ARAUCANÍA,ANGOL
t.c.collipulli,t.r.delaaraucania,2697,3009,COLLIPULLI,DE LA ARAUCANÍA,COLLIPULLI
t.c.curacautin,t.r.delaaraucania,2698,3009,CURACAUTIN,DE LA ARAUCANÍA,CURACAUTIN
t.c.ercilla,t.r.delaaraucania,2699,3009,ERCILLA,DE LA ARAUCANÍA,ERCILLA
t.c.lonquimay,t.r.delaaraucania,2700,3009,LONQUIMAY,DE LA ARAUCANÍA,LONQUIMAY
t.c.lossauces,t.r.delaaraucania,2701,3009,LOS SAUCES,DE LA ARAUCANÍA,LOS SAUCES
t.c.lumaco,t.r.delaaraucania,2702,3009,LUMACO,DE LA ARAUCANÍA,LUMACO
t.c.puren,t.r.delaaraucania,2703,3009,PUREN,DE LA ARAUCANÍA,PURÉN
t.c.renaico,t.r.delaaraucania,2704,3009,RENAICO,DE LA ARAUCANÍA,RENAICO
t.c.traiguen,t.r.delaaraucania,2705,3009,TRAIGUEN,DE LA ARAUCANÍA,TRAIGUÉN
t.c.victoria,t.r.delaaraucania,2706,3009,VICTORIA,DE LA ARAUCANÍA,VICTORIA
t.c.puertomontt,t.r.deloslagos,2707,3010,PUERTO MONTT,DE LOS LAGOS,PUERTO MONTT
t.c.calbuco,t.r.deloslagos,2708,3010,CALBUCO,DE LOS LAGOS,CALBUCO
t.c.cochamo,t.r.deloslagos,2709,3010,COCHAMO,DE LOS LAGOS,COCHAMÓ
t.c.fresia,t.r.deloslagos,2710,3010,FRESIA,DE LOS LAGOS,FRESIA
t.c.frutillar,t.r.deloslagos,2711,3010,FRUTILLAR,DE LOS LAGOS,FRUTILLAR
t.c.losmuermos,t.r.deloslagos,2712,3010,LOS MUERMOS,DE LOS LAGOS,LOS MUERMOS
t.c.llanquihue,t.r.deloslagos,2713,3010,LLANQUIHUE,DE LOS LAGOS,LLANQUIHUE
t.c.maullin,t.r.deloslagos,2714,3010,MAULLIN,DE LOS LAGOS,MAULLÍN
t.c.puertovaras,t.r.deloslagos,2715,3010,PUERTO VARAS,DE LOS LAGOS,PUERTO VARAS
t.c.castro,t.r.deloslagos,2716,3010,CASTRO,DE LOS LAGOS,CASTRO
t.c.ancud,t.r.deloslagos,2717,3010,ANCUD,DE LOS LAGOS,ANCUD
t.c.chonchi,t.r.deloslagos,2718,3010,CHONCHI,DE LOS LAGOS,CHONCHI
t.c.curacodevelez,t.r.deloslagos,2719,3010,CURACO DE VELEZ,DE LOS LAGOS,CURACO DE VÉLEZ
t.c.dalcahue,t.r.deloslagos,2720,3010,DALCAHUE,DE LOS LAGOS,DALCAHUE
t.c.puqueldon,t.r.deloslagos,2721,3010,PUQUELDON,DE LOS LAGOS,PUQUELDÓN
t.c.queilen,t.r.deloslagos,2722,3010,QUEILEN,DE LOS LAGOS,QUEILEN
t.c.quellon,t.r.deloslagos,2723,3010,QUELLON,DE LOS LAGOS,QUELLÓN
t.c.quemchi,t.r.deloslagos,2724,3010,QUEMCHI,DE LOS LAGOS,QUEMCHI
t.c.quinchao,t.r.deloslagos,2725,3010,QUINCHAO,DE LOS LAGOS,QUINCHAO
t.c.osorno,t.r.deloslagos,2726,3010,OSORNO,DE LOS LAGOS,OSORNO
t.c.puertooctay,t.r.deloslagos,2727,3010,PUERTO OCTAY,DE LOS LAGOS,PUERTO OCTAY
t.c.purranque,t.r.deloslagos,2728,3010,PURRANQUE,DE LOS LAGOS,PURRANQUE
t.c.puyehue,t.r.deloslagos,2729,3010,PUYEHUE,DE LOS LAGOS,PUYEHUE
t.c.rionegro,t.r.deloslagos,2730,3010,RIO NEGRO,DE LOS LAGOS,RÍO NEGRO
t.c.sanjuandelacosta,t.r.deloslagos,2731,3010,SAN JUAN DE LA COSTA,DE LOS LAGOS,SAN JUAN DE LA COSTA
t.c.sanpablo,t.r.deloslagos,2732,3010,SAN PABLO,DE LOS LAGOS,SAN PABLO
t.c.chaiten,t.r.deloslagos,2733,3010,CHAITEN,DE LOS LAGOS,CHAITÉN
t.c.futaleufu,t.r.deloslagos,2734,3010,FUTALEUFU,DE LOS LAGOS,FUTALEUFÚ
t.c.hualaihue,t.r.deloslagos,2735,3010,HUALAIHUE,DE LOS LAGOS,HUALAIHUÉ
t.c.palena,t.r.deloslagos,2736,3010,PALENA,DE LOS LAGOS,PALENA
t.c.coyhaique,t.r.deaysendelgeneralcarlosibanezdelcampo,2737,3011,COYHAIQUE,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,COYHAIQUE
t.c.lagoverde,t.r.deaysendelgeneralcarlosibanezdelcampo,2738,3011,LAGO VERDE,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,LAGO VERDE
t.c.aysen,t.r.deaysendelgeneralcarlosibanezdelcampo,2739,3011,AYSEN,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,AYSÉN
t.c.cisnes,t.r.deaysendelgeneralcarlosibanezdelcampo,2740,3011,CISNES,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,CISNES
t.c.guaitecas,t.r.deaysendelgeneralcarlosibanezdelcampo,2741,3011,GUAITECAS,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,GUAITECAS
t.c.cochrane,t.r.deaysendelgeneralcarlosibanezdelcampo,2742,3011,COCHRANE,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,COCHRANE
t.c.ohiggins,t.r.deaysendelgeneralcarlosibanezdelcampo,2743,3011,O'HIGGINS,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,O'HIGGINS
t.c.tortel,t.r.deaysendelgeneralcarlosibanezdelcampo,2744,3011,TORTEL,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,TORTEL
t.c.chilechico,t.r.deaysendelgeneralcarlosibanezdelcampo,2745,3011,CHILE CHICO,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,CHILE CHICO
t.c.rioibanez,t.r.deaysendelgeneralcarlosibanezdelcampo,2746,3011,RIO IBAÑEZ,DE AYSÉN DEL GENERAL CARLOS IBAÑEZ DEL CAMPO,RÍO IBAÑEZ
t.c.puntaarenas,t.r.demagallanesydelaantarticachilena,2747,3012,PUNTA ARENAS,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,PUNTA ARENAS
t.c.lagunablanca,t.r.demagallanesydelaantarticachilena,2748,3012,LAGUNA BLANCA,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,LAGUNA BLANCA
t.c.rioverde,t.r.demagallanesydelaantarticachilena,2749,3012,RIO VERDE,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,RÍO VERDE
t.c.sangregorio,t.r.demagallanesydelaantarticachilena,2750,3012,SAN GREGORIO,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,SAN GREGORIO
t.c.cabodehornos,t.r.demagallanesydelaantarticachilena,2751,3012,CABO DE HORNOS(EX-NAVARINO),DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,CABO DE HORNOS(EX-NAVARINO)
t.c.antartica,t.r.demagallanesydelaantarticachilena,2752,3012,ANTARTICA,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,ANTÁRTICA
t.c.porvenir,t.r.demagallanesydelaantarticachilena,2753,3012,PORVENIR,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,PORVENIR
t.c.primavera,t.r.demagallanesydelaantarticachilena,2754,3012,PRIMAVERA,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,PRIMAVERA
t.c.timaukel,t.r.demagallanesydelaantarticachilena,2755,3012,TIMAUKEL,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,TIMAUKEL
t.c.natales,t.r.demagallanesydelaantarticachilena,2756,3012,NATALES,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,NATALES
t.c.torresdelpaine,t.r.demagallanesydelaantarticachilena,2757,3012,TORRES DEL PAINE,DE MAGALLANES Y DE LA ANTÁRTICA CHILENA,TORRES DEL PAINE
t.c.santiago,t.r.metropolitanadesantiago,2758,3013,SANTIAGO,METROPOLITANA DE SANTIAGO,SANTIAGO
t.c.cerrillos,t.r.metropolitanadesantiago,2759,3013,CERRILLOS,METROPOLITANA DE SANTIAGO,CERRILLOS
t.c.cerronavia,t.r.metropolitanadesantiago,2760,3013,CERRO NAVIA,METROPOLITANA DE SANTIAGO,CERRO NAVIA
t.c.conchali,t.r.metropolitanadesantiago,2761,3013,CONCHALÍ,METROPOLITANA DE SANTIAGO,CONCHALÍ
t.c.elbosque,t.r.metropolitanadesantiago,2762,3013,EL BOSQUE,METROPOLITANA DE SANTIAGO,EL BOSQUE
t.c.estacioncentral,t.r.metropolitanadesantiago,2763,3013,ESTACION CENTRAL,METROPOLITANA DE SANTIAGO,ESTACION CENTRAL
t.c.huechuraba,t.r.metropolitanadesantiago,2764,3013,HUECHURABA,METROPOLITANA DE SANTIAGO,HUECHURABA
t.c.independencia,t.r.metropolitanadesantiago,2765,3013,INDEPENDENCIA,METROPOLITANA DE SANTIAGO,INDEPENDENCIA
t.c.lacisterna,t.r.metropolitanadesantiago,2766,3013,LA CISTERNA,METROPOLITANA DE SANTIAGO,LA CISTERNA
t.c.laflorida,t.r.metropolitanadesantiago,2767,3013,LA FLORIDA,METROPOLITANA DE SANTIAGO,LA FLORIDA
t.c.lagranja,t.r.metropolitanadesantiago,2768,3013,LA GRANJA,METROPOLITANA DE SANTIAGO,LA GRANJA
t.c.lapintana,t.r.metropolitanadesantiago,2769,3013,LA PINTANA,METROPOLITANA DE SANTIAGO,LA PINTANA
t.c.lareina,t.r.metropolitanadesantiago,2770,3013,LA REINA,METROPOLITANA DE SANTIAGO,LA REINA
t.c.lascondes,t.r.metropolitanadesantiago,2771,3013,LAS CONDES,METROPOLITANA DE SANTIAGO,LAS CONDES
t.c.lobarnechea,t.r.metropolitanadesantiago,2772,3013,LO BARNECHEA,METROPOLITANA DE SANTIAGO,LO BARNECHEA
t.c.loespejo,t.r.metropolitanadesantiago,2773,3013,LO ESPEJO,METROPOLITANA DE SANTIAGO,LO ESPEJO
t.c.loprado,t.r.metropolitanadesantiago,2774,3013,LO PRADO,METROPOLITANA DE SANTIAGO,LO PRADO
t.c.macul,t.r.metropolitanadesantiago,2775,3013,MACUL,METROPOLITANA DE SANTIAGO,MACÚL
t.c.maipu,t.r.metropolitanadesantiago,2776,3013,MAIPU,METROPOLITANA DE SANTIAGO,MAIPÚ
t.c.nunoa,t.r.metropolitanadesantiago,2777,3013,ÑUÑOA,METROPOLITANA DE SANTIAGO,ÑUÑOA
t.c.pedroaguirrecerda,t.r.metropolitanadesantiago,2778,3013,PEDRO AGUIRRE CERDA,METROPOLITANA DE SANTIAGO,PEDRO AGUIRRE CERDA
t.c.penalolen,t.r.metropolitanadesantiago,2779,3013,PEÑALOLEN,METROPOLITANA DE SANTIAGO,PEÑALOLEN
t.c.providencia,t.r.metropolitanadesantiago,2780,3013,PROVIDENCIA,METROPOLITANA DE SANTIAGO,PROVIDENCIA
t.c.pudahuel,t.r.metropolitanadesantiago,2781,3013,PUDAHUEL,METROPOLITANA DE SANTIAGO,PUDAHUEL
t.c.quilicura,t.r.metropolitanadesantiago,2782,3013,QUILICURA,METROPOLITANA DE SANTIAGO,QUILICURA
t.c.quintanormal,t.r.metropolitanadesantiago,2783,3013,QUINTA NORMAL,METROPOLITANA DE SANTIAGO,QUINTA NORMAL
t.c.recoleta,t.r.metropolitanadesantiago,2784,3013,RECOLETA,METROPOLITANA DE SANTIAGO,RECOLETA
t.c.renca,t.r.metropolitanadesantiago,2785,3013,RENCA,METROPOLITANA DE SANTIAGO,RENCA
t.c.sanjoaquin,t.r.metropolitanadesantiago,2786,3013,SAN JOAQUIN,METROPOLITANA DE SANTIAGO,SAN JOAQUÍN
t.c.sanmiguel,t.r.metropolitanadesantiago,2787,3013,SAN MIGUEL,METROPOLITANA DE SANTIAGO,SAN MIGUEL
t.c.sanramon,t.r.metropolitanadesantiago,2788,3013,SAN RAMON,METROPOLITANA DE SANTIAGO,SAN RAMÓN
t.c.vitacura,t.r.metropolitanadesantiago,2789,3013,VITACURA,METROPOLITANA DE SANTIAGO,VITACURA
t.c.puentealto,t.r.metropolitanadesantiago,2790,3013,PUENTE ALTO,METROPOLITANA DE SANTIAGO,PUENTE ALTO
t.c.pirque,t.r.metropolitanadesantiago,2791,3013,PIRQUE,METROPOLITANA DE SANTIAGO,PIRQUE
t.c.sanjosedemaipo,t.r.metropolitanadesantiago,2792,3013,SAN JOSE DE MAIPO,METROPOLITANA DE SANTIAGO,SAN JOSÉ DE MAIPO
t.c.colina,t.r.metropolitanadesantiago,2793,3013,COLINA,METROPOLITANA DE SANTIAGO,COLINA
t.c.lampa,t.r.metropolitanadesantiago,2794,3013,LAMPA,METROPOLITANA DE SANTIAGO,LAMPA
t.c.tiltil,t.r.metropolitanadesantiago,2795,3013,TILTIL,METROPOLITANA DE SANTIAGO,TILTIL
t.c.sanbernardo,t.r.metropolitanadesantiago,2796,3013,SAN BERNARDO,METROPOLITANA DE SANTIAGO,SAN BERNARDO
t.c.buin,t.r.metropolitanadesantiago,2797,3013,BUIN,METROPOLITANA DE SANTIAGO,BUIN
t.c.caleradetango,t.r.metropolitanadesantiago,2798,3013,CALERA DE TANGO,METROPOLITANA DE SANTIAGO,CALERA DE TANGO
t.c.paine,t.r.metropolitanadesantiago,2799,3013,PAINE,METROPOLITANA DE SANTIAGO,PAINE
t.c.melipilla,t.r.metropolitanadesantiago,2800,3013,MELIPILLA,METROPOLITANA DE SANTIAGO,MELIPILLA
t.c.alhue,t.r.metropolitanadesantiago,2801,3013,ALHUE,METROPOLITANA DE SANTIAGO,ALHUÉ
t.c.curacavi,t.r.metropolitanadesantiago,2802,3013,CURACAVI,METROPOLITANA DE SANTIAGO,CURACAVÍ
t.c.mariapinto,t.r.metropolitanadesantiago,2803,3013,MARIA PINTO,METROPOLITANA DE SANTIAGO,MARÍA PINTO
t.c.sanpedro,t.r.metropolitanadesantiago,2804,3013,SAN PEDRO,METROPOLITANA DE SANTIAGO,SAN PEDRO
t.c.talagante,t.r.metropolitanadesantiago,2805,3013,TALAGANTE,METROPOLITANA DE SANTIAGO,TALAGANTE
t.c.elmonte,t.r.metropolitanadesantiago,2806,3013,EL MONTE,METROPOLITANA DE SANTIAGO,EL MONTE
t.c.islademaipo,t.r.metropolitanadesantiago,2807,3013,ISLA DE MAIPO,METROPOLITANA DE SANTIAGO,ISLA DE MAIPO
t.c.padrehurtado,t.r.metropolitanadesantiago,2808,3013,PADRE HURTADO,METROPOLITANA DE SANTIAGO,PADRE HURTADO
t.c.penaflor,t.r.metropolitanadesantiago,2809,3013,PEÑAFLOR,METROPOLITANA DE SANTIAGO,PEÑAFLOR
t.c.valdivia,t.r.delosrios,2810,3014,VALDIVIA,DE LOS RÍOS,VALDIVIA
t.c.corral,t.r.delosrios,2811,3014,CORRAL,DE LOS RÍOS,CORRAL
t.c.lanco,t.r.delosrios,2812,3014,LANCO,DE LOS RÍOS,LANCO
t.c.loslagos,t.r.delosrios,2813,3014,LOS LAGOS,DE LOS RÍOS,LOS LAGOS
t.c.mafil,t.r.delosrios,2814,3014,MAFIL,DE LOS RÍOS,MÁFIL
t.c.mariquina,t.r.delosrios,2815,3014,MARIQUINA,DE LOS RÍOS,MARIQUINA
t.c.paillaco,t.r.delosrios,2816,3014,PAILLACO,DE LOS RÍOS,PAILLACO
t.c.panguipulli,t.r.delosrios,2817,3014,PANGUIPULLI,DE LOS RÍOS,PANGUIPULLI
t.c.launion,t.r.delosrios,2818,3014,LA UNION,DE LOS RÍOS,LA UNIÓN
t.c.futrono,t.r.delosrios,2819,3014,FUTRONO,DE LOS RÍOS,FUTRONO
t.c.lagoranco,t.r.delosrios,2820,3014,LAGO RANCO,DE LOS RÍOS,LAGO RANCO
t.c.riobueno,t.r.delosrios,2821,3014,RIO BUENO,DE LOS RÍOS,RÍO BUENO
t.c.arica,t.r.dearicayparinacota,2822,3015,ARICA,DE ARICA Y PARINACOTA,ARICA
t.c.camarones,t.r.dearicayparinacota,2823,3015,CAMARONES,DE ARICA Y PARINACOTA,CAMARONES
t.c.putre,t.r.dearicayparinacota,2824,3015,PUTRE,DE ARICA Y PARINACOTA,PUTRE
t.c.generallagos,t.r.dearicayparinacota,2825,3015,GENERAL LAGOS,DE ARICA Y PARINACOTA,GENERAL LAGOS
t.c.chillan,t.r.denuble,2826,3016,CHILLAN,DE ÑUBLE,CHILLÁN
t.c.bulnes,t.r.denuble,2827,3016,BULNES,DE ÑUBLE,BULNES
t.c.chillanviejo,t.r.denuble,2828,3016,CHILLAN VIEJO,DE ÑUBLE,CHILLÁN VIEJO
t.c.elcarmen,t.r.denuble,2829,3016,EL CARMEN,DE ÑUBLE,EL CARMEN
t.c.pemuco,t.r.denuble,2830,3016,PEMUCO,DE ÑUBLE,PEMUCO
t.c.pinto,t.r.denuble,2831,3016,PINTO,DE ÑUBLE,PINTO
t.c.quillon,t.r.denuble,2832,3016,QUILLON,DE ÑUBLE,QUILLÓN
t.c.sanignacio,t.r.denuble,2833,3016,SAN IGNACIO,DE ÑUBLE,SAN IGNACIO
t.c.yungay,t.r.denuble,2834,3016,YUNGAY,DE ÑUBLE,YUNGAY
t.c.quirihue,t.r.denuble,2835,3016,QUIRIHUE,DE ÑUBLE,QUIRIHUE
t.c.cobquecura,t.r.denuble,2836,3016,COBQUECURA,DE ÑUBLE,COBQUECURA
t.c.coelemu,t.r.denuble,2837,3016,COELEMU,DE ÑUBLE,COELEMU
t.c.ninhue,t.r.denuble,2838,3016,NINHUE,DE ÑUBLE,NINHUE
t.c.portezuelo,t.r.denuble,2839,3016,PORTEZUELO,DE ÑUBLE,PORTEZUELO
t.c.ranquil,t.r.denuble,2840,3016,RANQUIL,DE ÑUBLE,RÁNQUIL
t.c.treguaco,t.r.denuble,2841,3016,TREGUACO,DE ÑUBLE,TREGUACO
t.c.sancarlos,t.r.denuble,2842,3016,SAN CARLOS,DE ÑUBLE,SAN CARLOS
t.c.coihueco,t.r.denuble,2843,3016,COIHUECO,DE ÑUBLE,COIHUECO
t.c.niquen,t.r.denuble,2844,3016,ÑIQUEN,DE ÑUBLE,ÑIQUÉN
t.c.sanfabian,t.r.denuble,2845,3016,SAN FABIAN,DE ÑUBLE,SAN FABIÁN
t.c.sannicolas,t.r.denuble,2846,3016,SAN NICOLAS,DE ÑUBLE,SAN NICÓLAS
t.c.nada,t.r.gransantiago,88888,3017,LAS CONDES,GRAN SANTIAGO,
t.c.nada,t.r.granvalparaiso,88888,3018,LAS CONDES,GRAN VALPARAÍSO,
t.c.nada,t.r.granconcepcion,88888,3019,LAS CONDES,GRAN CONCEPCIÓN,
t.c.nada,t.r.macrozonasur,88888,3020,NADA,MACROZONA SUR`

export default parseCsv(territoriesCsv)