import _ from "lodash"

import Summary from "components/2022/plebiscito/[regionId]/Communes/RegionSection/Summary"
import territories from "components/2022/plebiscito/contextData/territories"
import { zones } from "components/2022/plebiscito/contextData/zonesData"
import { getRegionCommunes } from "components/2022/plebiscito/helpers"
import { filterTable, getAllData, getTerritoriesResult, sorterHandler as sorterHandlerBase } from "components/2022/plebiscito/RegionSection/helpers"

export const sorterHandler = ({ field, setSorter, sorter }) => {
  const newField = 'order' === field ? 'label' : field
  sorterHandlerBase({ field: newField, setSorter, sorter })
}

export const getTableData = ({ comparedElection, inputFilter, pEntrada, pSalida, regionId, selectedCommune, selectedUnit, setSelectedCommune, sorter, sortTable, sVuelta }) => {
  const allElectionsData = getAllData({ pEntrada, pSalida, sVuelta })
  const regionData = getRegionCommunes({ allElectionsData, regionId, zones })
  
  const communes = _.uniqBy(regionData, 'N_KEY')
  const dataByElection = _.groupBy(regionData, 'electionKey')
  const results = communes.map((commune) => {
    const communeResults = Object.entries(dataByElection).reduce((results, [electionKey, communes]) => {
      const selectedCommune = communes.find(c => c.N_KEY === commune.N_KEY)
      results[electionKey] = { ...getTerritoriesResult([selectedCommune]), communeData: commune }
      return results
    }, {})
    
    const communeName = territories.find(territory => territory.n_key_comuna === commune.N_KEY)?.label.toLowerCase()
    const isCommuneSelected = commune.N_KEY === selectedCommune
    return {
      key: commune.N_KEY.toLowerCase(),
      label: communeName,
      onClick: () => setSelectedCommune(isCommuneSelected ? '' : commune.N_KEY),
      results: communeResults,
      rowItem: (
        <Summary communeKey={commune.N_KEY}
          communeName={communeName}
        />
      )
    }
  }, {})
  const selectedCommuneData = results.find(t => t.key === selectedCommune)
  if (selectedCommuneData) {
    selectedCommuneData.selected = true
  }

  const sortedData = sortTable({ data: results, sorter })
  const filteredData = filterTable({ comparedElection, data: sortedData, inputFilter, selectedUnit })
  return filteredData
}
