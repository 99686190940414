import React from 'react'
import styled from 'styled-components'

import { colors } from 'components/global-parameters'

export const LiveIndicator = ({
  animated = true,
  centered = true,
  className,
  handleClick = () => { },
  queryName,
}) => {

  const isMobileDevice = ['small', 'phone'].includes(queryName)
  return (
    <Container centered={centered}
      className={className}
      onClick={handleClick}
    >
      <LiveCircle animated={animated}
        smallDevice={isMobileDevice}
      />
      <VideoTitle smallDevice={isMobileDevice}>{'LIVE'}</VideoTitle>
    </Container>
  )
}

const VideoTitle = styled.div`
  padding-left: 8px;
  font-weight: ${props => (props.smallDevice ? 700 : 500)};
  font-size: 12em;
  line-height: 21px;
  text-align: center;
  color: ${colors.colorRed1};
  cursor: ${props => (props.smallDevice ? 'pointer' : 'default')};
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.smallDevice ? 'center' : 'left')};
`

const LiveCircle = styled.div`
  background-color: red;
  border-radius: 50%;
  text-align: center;
  width: ${props => (props.smallDevice ? 8 : 10)}px;
  height: ${props => (props.smallDevice ? 8 : 10)}px;
  ${props => (props.animated ? `
    @keyframes live {
      0% {opacity: 1;}
      50% {opacity: 0;}
      100% {opacity: 1;}
    }
    animation-name: live;
    animation-duration: 1.75s;
    animation-iteration-count: infinite;
    ` : '')
  };
`

export default LiveIndicator