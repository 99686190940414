import { Select as SelectBase } from 'antd'
import styled, { css } from 'styled-components'

import { colors,spaces } from 'components/global-parameters'

const { Option } = SelectBase

export const TitleHeader = ({ bold=false, children, className, onSelect, options, selectedOption }) => {
  return (
    <Wrapper className={className}>
      {options ? (
        <Select bordered={false}
          dropdownStyle={{
            fontFamily: 'Jetbrains Mono',
          }}
          onChange={onSelect}
          value={selectedOption}
        >
          {options.map(({ label, value }) => (
            <Option key={value}
              value={value}
            >
              {label}
            </Option>
          ))}
        </Select>
      ) : <Title bold={bold}>{children}</Title>}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaces.m};
`

export const TitleCss = css`
  text-align: center;
  font-size: 30em;
  font-weight: 300;
  line-height: 32px;
  `

export const Title = styled.h1`
  ${TitleCss}
  ${props => props.bold && 'font-weight: 400;'}
`

const Select = styled(SelectBase)`
  ${TitleCss}
  .ant-select-arrow{
    color: ${colors.colorBlack};
  }
`

export default TitleHeader