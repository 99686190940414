import styled from "styled-components"

import { colors } from "components/global-parameters"

const HorizontalSeparator = styled.div`
  width: 100%;
  margin: 60px 0;
  border-bottom: 2px solid ${colors.colorGrey13};
`

export default HorizontalSeparator